import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@mui/lab';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip } from '@material-ui/core';
import Switch from '@mui/material/Switch';
import SearchBar from "material-ui-search-bar";
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FRONT_END_URL } from '../../../utils/config/config';
import Box from "@mui/material/Box";
import {useDropzone} from 'react-dropzone';
import { COLOR_THEME } from '../common/constants'
import NewRequirement from './new_requirement';
import EditRequirement from './edit_requirement';
import EditIcon from '@mui/icons-material/Edit';
// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	topblock: {
		// display: 'flex'
	},
    dropzone: {
        textAlign: "center",
        padding: "10px",
        border: "1px #bdbdbd solid",
        backgroundColor: "#fafafa",
        color: "#717171",
        [theme.breakpoints.up('sm')]: {
            padding: "20px",
        },
    },
    type_option: {
        padding: '10px',
        maxWidth: '50vw',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            border: '1px solid #093D61',
        },

    }
}));
 
//Function that pulls the data and sends it to the Table Renderer
const ProjectSettings = props => {
    let {loading, formSubmitContainer, notifications,project_image, requirements_data, createRequirementType, updateRequirementType,requirement_types_tab,updateNotifications } = props;
    const classes = useStyles();
    const [tab, setTab] = React.useState('Details');
    // Create New Requirement Type
    const [showDialog, setShowDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [currentInformation, setCurrentInformation] = useState({
        name: '',
        type: '',
        decription: '',
        sample_file: ''
    });

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({ 
        maxFiles: 1});

    useEffect(() => {
        // filterRequirements(``)    
        if (requirement_types_tab) {
            setTab('Requirement Types')
        }
    },[requirement_types_tab])

    let changeTab = (e, value) => {
        setTab(value)
    }

    const formSubmit = () => {
        var imageFormData = new FormData();
        imageFormData.append('image_file', acceptedFiles[0]);
        formSubmitContainer(imageFormData);
    }

    return (
        <>
          {loading ? (
            <>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                Loading...
              </div>
            </>
            ) : (
            <div className={classes.content}>
				<div className={classes.topblock}>
					<div>
						<Typography variant="h4">Project Settings</Typography>
					</div>
                    <br />
    				<br />
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={tab} onChange={(e, newValue) => changeTab(e, newValue)}>
                            <Tab label="Details" value='Details'/>
                            <Tab label="Requirement Types and Categories" value='Requirement Types'/>
                        </Tabs>
                    </Box>
				</div>
				<br />
				<br />
				<div>
                    {tab === 'Details' && (
                        <div>
                        <Typography variant='h6'>
                            Project Image
                        </Typography>
                        <section>
                            <div {...getRootProps({className: classes.dropzone})}>
                                <input {...getInputProps()} />
                                <Typography variant='subtitle1'>Drag 'n' drop or click to select an image file</Typography>
                            </div>
                            <br />
                            {acceptedFiles.length === 0 && project_image && (
                                <div style={{display: 'flex', alignItems: 'center', flexWrap:'wrap'}}>
                                   <Typography variant='subtitle1'>Current File:</Typography>&nbsp;
                                    <Chip 
                                        icon={<ImageIcon />} 
                                        label={'Project Image'} 
                                        color='primary'
                                        size='small'
                                        onClick={() => window.open(project_image, '_blank')}
                                    />
                                </div>                            
                            )}
                            {acceptedFiles.length > 0 && (
                                <div style={{display: 'flex', alignItems: 'center', flexWrap:'wrap'}}>
                                    <Typography variant='subtitle1'>New Selected File:</Typography>&nbsp;
                                    {(acceptedFiles[0].type.includes('png')||acceptedFiles[0].type.includes('jpg')||acceptedFiles[0].type.includes('jpeg')) && (
                                        <>
                                            <Chip 
                                                icon={<ImageIcon />} 
                                                label={acceptedFiles[0].path.substring(0,25)} 
                                                color='primary'
                                                size='small'
                                            />&nbsp;&nbsp;
                                            <div style={{marginLeft:"auto"}}>
                                            <Button 
                                                variant="contained" 
                                                style={{backgroundColor: COLOR_THEME.classic_blue, color: 'white'}}
                                                onClick={() => formSubmit()}
                                            >
                                                Save
                                            </Button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </section>
                        <br /><br />
                        <div>
                            <div>
                                <Paper 
                                    className={classes.type_option}
                                >
                                    <div>
                                        <Typography variant='h6'>
                                            Submission Notifications
                                        </Typography>
                                        <Typography variant='subtitle'>
                                            Receive email notifications when a requirement is uploaded.
                                        </Typography>
                                    </div>
                                    <div style={{marginLeft: 'auto'}}>
                                        Off
                                        <Switch 
                                            checked={notifications}
                                            onChange={(e) => {
                                                let body = {
                                                    notifications : e.target.checked
                                                }
                                                updateNotifications(body)
                                            }}
                                        />
                                        On
                                    </div>
                                </Paper>
                            </div>

                        </div>
                    </div>
                )}
                    {tab === 'Requirement Types' && (
                        <div style={{display: 'flex', alignItems: 'center', flexWrap:'wrap'}}>
                            <div>
                                <Button 
                                    variant="contained" 
                                    style={{backgroundColor: COLOR_THEME.classic_blue, color: 'white'}}
                                    onClick={()=> {
                                        setShowDialog(true)
                                    }}
                                >
                                    New Requirement Type
                                </Button>
                                {/* DialogBox */}
                                <div>
                                    <NewRequirement 
                                        showDialog={showDialog}
                                        setShowDialog={setShowDialog}
                                        createRequirementType={createRequirementType}
                                    />
                                    <EditRequirement 
                                        showEditDialog={showEditDialog}
                                        setShowEditDialog={setShowEditDialog}
                                        updateRequirementType={updateRequirementType}
                                        currentInformation={currentInformation}
                                        setCurrentInformation={setCurrentInformation}
                                    />
                                </div>

                                <br />
                                <br />

                                <TableContainer component={Paper}>
                                    <Table 
                                        sx={{ width: 650 }} 
                                        stickyHeader aria-label="simple table"
                                    >
                                        <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Parent Type</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Sample File</TableCell>
                                            <TableCell align='right'>Actions</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {requirements_data.map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>{row.parent_type}</TableCell>
                                                <TableCell>{row.description ? row.description : '-'}</TableCell>
                                                <TableCell>{row.sample_file ? (
                                                    <>
                                                        <Chip 
                                                            icon={<InsertDriveFileIcon />} 
                                                            label={'Sample File'} 
                                                            color='primary'
                                                            size='small'
                                                            onClick={() => window.open(row.sample_file, "_blank")}
                                                        />
                                                    </>) : '-'}</TableCell>
                                                <TableCell><EditIcon fontSize={'small'} onClick={() => {
                                                    setShowEditDialog(true)
                                                    setCurrentInformation({
                                                        'id': row.id,
                                                        'type': row.parent_type,
                                                        'category': row.name,
                                                        'description': row.description,
                                                        'sample_file': row.sample_file
                                                    })
                                                }}/></TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    )}
				</div>
            </div>
          )}
        </>
    );
};


export default ProjectSettings;