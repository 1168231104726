import React, {useState, useEffect} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Checkbox from "@mui/material/Checkbox";

import { COLOR_THEME } from '../common/constants'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        maxHeight: '70vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	topblock: {
		display: 'flex'
	},
    cancelButton: {
        color: 'white', 
        backgroundColor: COLOR_THEME.light_blue,        
        },
    saveButton: {
        color: 'white', 
        backgroundColor: COLOR_THEME.classic_blue,        
        "& .Mui-disabled": {
            backgroundColor: 'gray',
            }
    },
}));

const SelectSubmittalsDialog = props => {
    let {loading, showSelectSubmittalsDialog, setShowSelectSubmittalsDialog, procore_sync_submittal_data, existing_submittal_selection, saveSubmittalsSelection} = props;
    const classes = useStyles();

    const [selected, setSelected] = React.useState([]);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelected = procore_sync_submittal_data.map((n) => n.id);
          setSelected(newSelected);
          return;
        }
        setSelected([]);
    };
    
    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
   
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
            }      
        setSelected(newSelected);
    };
    const isSelected = (id) => selected.indexOf(id) !== -1;

    useEffect(() => {        
        setSelected(existing_submittal_selection)
    },[loading,existing_submittal_selection])

    const ChangeStep = (type) => {
        if (type==='cancel') {
            setSelected([])
        } else if (type==='save') {
            saveSubmittalsSelection({selected: selected})
        }
        setShowSelectSubmittalsDialog(false)
    }

    return (
        <>
          {loading ? (
            <>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                Loading...
              </div>
            </>
            ) : (
            <div className={classes.content}>
                <Dialog
                    open={showSelectSubmittalsDialog}
                    onClose={() => ChangeStep('cancel')}
                    fullWidth
                    maxWidth="md"
                    style={{maxHeight: '90vh', marginTop: '50px'}}
                >
                    <DialogTitle>Select the Submittals to be included in the Wrap Up Package</DialogTitle>
                    <DialogContent>
                        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={procore_sync_submittal_data.length > 0 && selected.length === procore_sync_submittal_data.length}
                                                onChange={handleSelectAllClick}
                                                inputProps={{"aria-label": "select all"}}
                                            />
                                        </TableCell>
                                        <TableCell>Number</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {procore_sync_submittal_data.map((row, index) => {
                                    return(
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.id)}
                                            tabIndex={-1}
                                            selected={isSelected(row.id)}
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    icon = {<CheckBoxOutlineBlankIcon />}
                                                    checked={isSelected(row.id)}
                                                    inputProps={{
                                                        "aria-labelledby": `enhanced-table-checkbox-${index}`
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row">{row.spec_number}-{row.number}.{row.revision}</TableCell>
                                            <TableCell component="th" scope="row">{row.title}</TableCell>
                                            <TableCell component="th" scope="row">{row.status}</TableCell>
                                        </TableRow>
                                    )
                                })}
                                </TableBody>
                            </Table>                        
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained"
                            className={classes.cancelButton}
                            onClick={() => ChangeStep('cancel')}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained"
                            className={classes.saveButton}
                            onClick={() => ChangeStep('save')}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
          )}
        </>
    );

}

export default SelectSubmittalsDialog;