import React, {useState, useEffect} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";

import { COLOR_THEME } from '../common/constants'
import Navigation from './navigation'

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	topblock: {
		display: 'flex'
	},
    nextButton: {
        color: 'white', 
        backgroundColor: COLOR_THEME.classic_blue,        
        "& .Mui-disabled": {
            backgroundColor: 'gray',
            }
        },
}));
 
//Function that pulls the data and sends it to the Table Renderer
const ProjectDirectorySetup = props => {
    let {loading, procore_data, saveDirectoryData, existing_directory, subs_with_commitments} = props;
    const classes = useStyles();

    let mode = Object.values(existing_directory).flat().length > 0 ? 'edit' : 'new'

    // steps: 0: Your Company, 1: Owner, 2: Subs, 3: Review
    const [activeStep, setActiveStep] = useState(2);

    const [selected, setSelected] = React.useState([]);
    const [directory, setDirectory] = useState({});
    const [rows, setRows] = useState(procore_data);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelected = rows.map((n) => n.id);
          setSelected(newSelected);
          return;
        }
        setSelected([]);
      };
    
    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
   
        if (activeStep !== 2) {
            newSelected = [id]
        } else {
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
              } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
              } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
              } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                  selected.slice(0, selectedIndex),
                  selected.slice(selectedIndex + 1)
                );
              }      
        }
        setSelected(newSelected);
      };
    
    const isSelected = (id) => selected.indexOf(id) !== -1;
    // For mode=edit, this pre defines the variables.
    useEffect(() => {
        setActiveStep(mode === 'new' ? 2 : 2);
        
        if (Object.keys(existing_directory).length > 0) {
            setDirectory(existing_directory)
            if (Object.keys(existing_directory).includes('2')) {
                setSelected(existing_directory[2])
            }
            let temp_procore_data = [...procore_data]
            setRows(temp_procore_data.filter(e => ![].concat.apply([], ['0','1'].map(function(k){return existing_directory[k]})).concat(selected).includes(e.id)))
        }
    },[loading])

    useEffect(() => {
        window.scrollTo(0, 0)
    },[activeStep])

    const ChangeStep = (type) => {
        if (type==='next') {
            setActiveStep(activeStep+1)
            setDirectory({...directory, [activeStep]: selected})
            let temp_procore_data = [...procore_data]

            if (activeStep===2) {
                // Going into review step
                let gc = directory['0'][0]
                let owner = directory['1'][0]
                let sub = selected
                
                temp_procore_data.map(e => {
                    if (sub.includes(e.id)) {
                        e['type'] = 'Subcontractor'
                    } else if (e.id === gc) {
                        e['type'] = 'General Contractor'
                    } else if (e.id === owner) {
                        e['type'] = 'Owner'
                    } else {
                        e['type'] = 'Inactive'
                    }
                })
                // temp_procore_data.filter(e => e.type !== 'Inactive')
                setRows(temp_procore_data)
            } else {
                setRows(temp_procore_data.filter(e => ![].concat.apply([], Object.keys(directory).map(function(k){return directory[k]})).concat(selected).includes(e.id)))
            }

            if (activeStep===1) {
                // Pre selecting the subs with commitments
                setSelected(subs_with_commitments)
            } else {
                setSelected([])
            }
        } else if (type==='back') {
            let temp_procore_data = [...procore_data]
            setSelected([])
            setDirectory({...directory, [activeStep]: []})
            setActiveStep(activeStep-1)
            setRows(temp_procore_data.filter(e => ![].concat.apply([], Object.keys(directory).filter(e => parseInt(e)<activeStep-1).map(function(k){return directory[k]})).includes(e.id)))
        } else if (type==='create') {
            setActiveStep(activeStep+1)
            saveDirectoryData(rows, mode)
        }
    }

    // console.log(directory)

    return (
        <>
          {loading ? (
            <>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                Loading...
              </div>
            </>
            ) : (
            <div className={classes.content}>
				<div className={classes.topblock}>
					<div>
						<Typography variant="h4">Import Directory</Typography>
					</div>
				</div>
                <br />
                <div>
                    <Navigation activeStep={activeStep}/>
                    <br />
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }} />
                    <br />
                </div>
				<br />
				<div>
					<div>
                        {activeStep === 2 && (
                           <Typography>We have preselected the subcontractors with commitments in Procore.</Typography> 
                        )}
                        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {activeStep !== 2 ? (
                                            <TableCell padding="checkbox">
                                            </TableCell>
                                        ) : (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={rows.length > 0 && selected.length === rows.length}
                                                    onChange={handleSelectAllClick}
                                                    inputProps={{"aria-label": "select all"}}
                                                />
                                            </TableCell>
                                        )}
                                        <TableCell>Name</TableCell>
                                        <TableCell>Trade(s)</TableCell>
                                        {activeStep === 3 && (
                                            <TableCell>Type</TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {rows.map((row, index) => {
                                    return(
                                    // {(row.type !=='Inactive') && (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.id)}
                                            tabIndex={-1}
                                            selected={isSelected(row.id)}
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            {activeStep < 3 ? (
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        icon = {activeStep < 2 ? <RadioButtonUncheckedIcon /> : <CheckBoxOutlineBlankIcon />}
                                                        checked={isSelected(row.id)}
                                                        inputProps={{
                                                            "aria-labelledby": `enhanced-table-checkbox-${index}`
                                                        }}
                                                    />
                                                </TableCell>
                                            ) : (
                                                <TableCell component="th" scope="row">
                                                    
                                                </TableCell>
                                            )}
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.trades}
                                            </TableCell>
                                        {activeStep === 3 && (
                                            <TableCell component="th" scope="row">
                                                {row.type}
                                            </TableCell>
                                        )}
                                        </TableRow>
                                    )
                                    // )}
                                })}
                                </TableBody>
                            </Table>
                        
                        </TableContainer>
                    <br />
                    <div style={{display: 'flex', justifyContent:'flex-end'}}>
                    { activeStep > (mode === 'new' ? 2 : 2) && (
                        <>
                            <Button variant="contained" style={{color: 'white', backgroundColor: COLOR_THEME.light_blue}}
                                onClick={() => ChangeStep('back')}
                            >
                                Back
                            </Button>&emsp;
                        </>                    
                    )}
                    { activeStep < 3 && (
                        <Button variant="contained"
                            disabled={selected.length===0 ? true:false}
                            className={classes.nextButton}
                            onClick={() => ChangeStep('next')}
                        >
                            Next
                        </Button>
                    )}
                    { activeStep == 3 && (
                        <>
                            <Button variant="contained"
                                className={classes.nextButton}
                                onClick={() => ChangeStep('create')}
                            >
                                Create
                            </Button>
                        </>
                    )}
                </div>
                    </div>
				</div>
            </div>
          )}
        </>
    );
};


export default ProjectDirectorySetup;