
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { COLOR_THEME } from '../common/constants'

const steps = [
    {
        label: "Upload Project Image",
        description: `Upload a project image to use as cover image for the Close-out Documentation.`,
        button_label: 'Upload',
        location: '/project_settings/project_image'
    },
    {
        label: "Setup Project Directory",
        description: `Setup the directory of your projects which consists of companies and contacts imported from Procore.`,
        button_label: 'Setup Directory',
        location: '/project_directory_setup'
    },
    {
        label: "Review Closeout Documents Types and Categories",
        description: `Setup the type of documents required as part of the project closeout.`,
        button_label: 'Review Categories',
        location: '/project_settings/requirement_types'
    }
];

export default function VerticalStepper(props) {
//   const [activeStep, setActiveStep] = React.useState(0);

  return (
    <Box sx={{ maxWidth: 600 }}>
      <Stepper activeStep={props.activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    style={{backgroundColor: COLOR_THEME.classic_blue, color: 'white'}}
                    onClick={() => props.setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                    sx={{ mt: 1, mr: 1 }}
                    component={Link}
                    to={step.location}
                  >
                    {step.button_label}
                  </Button>
                  {step.label === 'Upload Project Image' && (
                    <Button
                        variant="contained"
                        style={{backgroundColor: COLOR_THEME.light_blue, color: 'white'}}
                        onClick={() => props.setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                        sx={{ mt: 1, mr: 1 }}
                        component={Link}
                        to={'/project_directory_setup'}
                    >
                        Skip
                    </Button>
                  )}
                  {/* <Button
                    disabled={index === 0}
                    onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button> */}
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
