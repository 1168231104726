import React, { Component } from 'react';
import Axios from 'axios';
import _, { filter } from 'lodash';

import { API_URL } from '../../utils/config/config';
import { withAuth0 } from "@auth0/auth0-react";
import Header from '../../components/post-security/common/header';
import Sidebar from '../../components/post-security/common/sidebar';
import ProjectSettings from '../../components/post-security/project_settings/project_settings';

import {Typography, Button, Paper, Dialog, withWidth} from '@material-ui/core';
import { Replay } from '@mui/icons-material';
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@mui/lab/Alert';

import { withStyles } from '@material-ui/core';
import { tickStep } from 'd3';

const Styles = theme => ({
    root_grid: {
        display: 'grid',
        gridTemplateRows: '65px auto',
        overflowY: 'visible',
    },
    header: {
        zIndex: 999999,
        maxHeight: '65px',
    },
    inner_grid: {
        display: 'grid',
        gridTemplateColumns: '155px auto',
        overflowY: 'visible',
    },
});

class ProjectDirectoryContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            offline: false,
            project_image_tab: false,
            requirement_types_tab: false,
            snackbar_show: false,
            snackbar_message: '',
            snackbar_type: 'info',
            notifications: false,
            project_image: null,
            requirements_data: [],
        };
        this.formSubmitContainer = this.formSubmitContainer.bind(this); 
        this.createRequirementType = this.createRequirementType.bind(this);
        this.updateRequirementType = this.updateRequirementType.bind(this);
        this.updateNotifications = this.updateNotifications.bind(this);
    }

    fetchData = async () => {
        try {
            const api_header = {
                'content-type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            }
            
            const response = await Axios.get(`${API_URL}fetch_project_settings/${localStorage.getItem('user_id')}/`, {
                headers: api_header,
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });
 
            this.setState({
                ...this.state,
                loading: false,
                notifications: response.data.notifications,
                project_image: response.data.project_image,
                requirements_data: response.data.requirements_data
            })
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    updateNotifications = async (body) => {
        try {
            this.setState({snackbar_show: true});
            this.setState({snackbar_message: 'Updating...'});
            this.setState({snackbar_type: 'info'});  

            const api_header = {
                'content-type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            }
            const saveLesson = await Axios.post(`${API_URL}update_project_notifications/${localStorage.getItem('user_id')}/`, 
            body,
            {
                headers: api_header
            })

            if (saveLesson.status===200) {
                this.setState({snackbar_show: true});
                this.setState({snackbar_message: 'Submission Notifications has been updated.'});
                this.setState({snackbar_type: 'success'}); 
                this.setState({notifications: saveLesson.data.notifications})       
            }    
        } catch (error) {
            console.log(error)
            this.setState({snackbar_show: true});
            this.setState({snackbar_message: 'Submission Notifications has been failed to be updated.'});
            this.setState({snackbar_type: 'error'});        
        }
    }


    formSubmitContainer = async (imageFormData) => {
        try {
            this.setState({snackbar_show: true});
            this.setState({snackbar_message: 'Uploading...'});
            this.setState({snackbar_type: 'info'});  

            const saveLesson = await Axios.patch(`${API_URL}projects/${localStorage.getItem('active_project')}/`, 
            imageFormData,
            {
                headers: {
                    'Content-Type': `multipart/form-data`,
                },
            })

            if (saveLesson.status===200) {
                this.setState({snackbar_show: true});
                this.setState({snackbar_message: 'Project Cover Image has been uploaded.'});
                this.setState({snackbar_type: 'success'});        
            }    
            if (this.state.project_image_tab) {
                this.props.history.push('/new_project_setup/1')
            }

        } catch (error) {
            console.log(error)
            this.setState({snackbar_show: true});
            this.setState({snackbar_message: 'Project Cover Image has been failed to upload.'});
            this.setState({snackbar_type: 'error'});        
        }
    }
    
    createRequirementType = async (requirementFormData) => {
        try {
            this.setState({snackbar_show: true});
            this.setState({snackbar_message: 'Creating...'});
            this.setState({snackbar_type: 'info'});  

            const saveLesson = await Axios.post(`${API_URL}requirement_type/`, 
            requirementFormData,
            {
                headers: {
                    'Content-Type': `multipart/form-data`,
                },
            })

            if (saveLesson.status===201) {
                this.setState({snackbar_show: true});
                this.setState({snackbar_message: 'Requirement Type successfully created.'});
                this.setState({snackbar_type: 'success'});        
            }    
            this.fetchData();
        } catch (error) {
            console.log(error)
            this.setState({snackbar_show: true});
            this.setState({snackbar_message: 'Requirement Type failed to be created.'});
            this.setState({snackbar_type: 'error'});        
        }
    }

    updateRequirementType = async (requirementFormData, requirement_id) => {
        try {
            this.setState({snackbar_show: true});
            this.setState({snackbar_message: 'Updating...'});
            this.setState({snackbar_type: 'info'});  

            const saveLesson = await Axios.patch(`${API_URL}requirement_type/${requirement_id}/`, 
            requirementFormData,
            {
                headers: {
                    'Content-Type': `multipart/form-data`,
                },
            })

            if (saveLesson.status===200) {
                this.setState({snackbar_show: true});
                this.setState({snackbar_message: 'Requirement Type successfully updated.'});
                this.setState({snackbar_type: 'success'});        
                this.fetchData();
            }    
        } catch (error) {
            console.log(error)
            this.setState({snackbar_show: true});
            this.setState({snackbar_message: 'Requirement Type failed to be updated.'});
            this.setState({snackbar_type: 'error'});        
        }
    }


    componentDidMount() {
        let { new_project } = this.props.match.params;
        if (new_project=== 'project_image') {
            this.setState({project_image_tab: true})
        } else if (new_project=== 'requirement_types') {
            this.setState({requirement_types_tab: true})
        }

        this.fetchData();
    }

    render() {  

        const { classes } = this.props;        
        return (
            <>
                <div className={classes.root_grid}>
                    <div className={classes.header}>
                        <Header />
                    </div>
                    <div>
                        {this.state.offline ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                You are offline!
                            </div>
                        ) : this.state.error ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                                    <strong>An error occured!</strong>
                                </Typography>
                                <center>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => window.location.reload()}
                                    >
                                        <Replay /> Try again
                                    </Button>
                                </center>
                            </div>
                        ) : (      
                                <div className={classes.inner_grid}>
                                    <Snackbar 
                                        anchorOrigin={{ vertical: 'bottom',horizontal: 'center'}}
                                        open={this.state.snackbar_show}
                                        autoHideDuration={3000}
                                        onClose={() => this.setState({snackbar_show: false})}
                                    >
                                        <MuiAlert elevation={6} variant="filled" severity={this.state.snackbar_type}>
                                            {this.state.snackbar_message}
                                        </MuiAlert>
                                    </Snackbar>

                                    <div><Sidebar /></div>                                    
                                    <div  className={classes.main}>
                                        <ProjectSettings 
                                            loading={this.state.loading}
                                            formSubmitContainer={this.formSubmitContainer}
                                            notifications={this.state.notifications}
                                            project_image={this.state.project_image}
                                            requirements_data={this.state.requirements_data}
                                            createRequirementType={this.createRequirementType}
                                            updateRequirementType={this.updateRequirementType}
                                            requirement_types_tab={this.state.requirement_types_tab}
                                            updateNotifications={this.updateNotifications}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    <div>
                    </div>                
                </div>

            </>
        );
    }
}

export default withAuth0(withStyles(Styles)(ProjectDirectoryContainer));
