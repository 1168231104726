import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import fuzzysort from 'fuzzysort';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import SearchBar from "material-ui-search-bar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import loading_gif from '../common/loading.gif'

import { COLOR_THEME } from '../common/constants'

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	topblock: {
		display: 'flex'
	}
}));
 
//Function that pulls the data and sends it to the Table Renderer
const Projects = props => {
    let {loading, projects_data, changeActiveProject } = props;
    const classes = useStyles();
	const [projectsTab, setProjectsTab] = React.useState(0);
    
    const [rows, setRows] = useState(projects_data);

    useEffect(() => {
        setRows(projects_data)
    },[projects_data])

    const [searched, setSearched] = useState("");

    const requestSearch = (searchedVal) => {
        if (searchedVal === '') {
            setRows(projects_data)
        } else {
            let search_results = fuzzysort.go(searchedVal, projects_data, {keys:['name','project_number']}).map(e => e.obj)
            setRows(search_results);    
        }
    };
    
    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    return (
        <>
          {loading ? (
            <>
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <img src={loading_gif} style={{height: '200px', display: 'block', margin: 'auto'}}/>
                    <Typography variant='h6' style={{ textAlign: 'center'}}>
                        Please wait while we authenticate you, do light dusting on our database, load obnoxious amount of javascript, and bore you with a long loading message...
                    </Typography>
                </div>
            </>
            ) : (
            <div className={classes.content}>
				<div className={classes.topblock}>
					<div>
						<Typography variant="h4">Projects</Typography>
					</div>
					<div style={{marginLeft: 'auto'}}>
						<Button 
                            variant="contained" 
                            style={{backgroundColor: COLOR_THEME.classic_blue, color: 'white'}}
                            component={Link}
                            to='/new'
                        >
                            Find a Project
                        </Button>
					</div>
				</div>
				<br />
				<div>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={projectsTab} onChange={(e, newValue) => setProjectsTab(newValue)}>
                        <Tab label="In Progress"/>
                        <Tab label="Completed"/>
                    </Tabs>
                    </Box>
				</div>
				<br />
				<div>
                    <div></div>
					<div>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <SearchBar
                        value={searched}
                        onChange={(searchVal) => requestSearch(searchVal)}
                        onCancelSearch={() => cancelSearch()}
                        placeholder='Search projects'
                    />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table 
                            sx={{ minWidth: 650 }} 
                            stickyHeader aria-label="simple table"
                        >
                            <TableHead>
                            <TableRow>
                                <TableCell style={{width: '200px'}}>Project Number</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Construction End Date</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>{row.project_number ? row.project_number : ''}</TableCell>
                                    <TableCell component="th" scope="row" style={{display:'flex', cursor: 'pointer'}}
                                        onClick={() => {
                                            changeActiveProject(row.id)
                                            localStorage.setItem('active_project_number',row.project_number)
                                            localStorage.setItem('active_project_name',row.name)
                                        }}
                                    >
                                        {row.name}
                                        &ensp;
                                        <ArrowOutwardIcon fontSize='small' style={{alignSelf: 'center', color: COLOR_THEME.light_blue}} />
                                    </TableCell>
                                    <TableCell align="right">{row.end_date ? row.end_date.substring(0,10) : 'No Date Found'}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </div>
				</div>
            </div>
          )}
        </>
    );
};


export default Projects;