import React, {useState, useEffect} from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { COLOR_THEME } from '../common/constants'


const project_list = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 }
]
export default function ProjectSelector(props) {
    let { active_projects_list, setSelectedProject } = props
    let dropdown_input = []
    if (active_projects_list) {
        dropdown_input = active_projects_list.map(i => ({'title': i.name, 'id': i.id, 'project_number': i.project_number}))
    }
    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={dropdown_input}
            getOptionLabel={(option) => option.project_number ? option.project_number +" "+ option.title : option.title}
            onChange={(e, value) => {
                setSelectedProject(active_projects_list.find(project => project.id === value.id))
            }}
            // sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Select a Project form Procore" />}
        />            
    );
}
  