import React, { Component } from 'react';
import Axios from 'axios';
import _, { filter } from 'lodash';
import { API_URL } from '../../utils/config/config';
import { withAuth0 } from "@auth0/auth0-react";
import queryString from 'query-string'
import Header from '../../components/post-security/common/header';
import Sidebar from '../../components/post-security/common/sidebar';
import ProjectHome from '../../components/post-security/project_home/project_home';

import {Typography, Button, Paper, Dialog, withWidth} from '@material-ui/core';
import { Replay } from '@mui/icons-material';

import { withStyles } from '@material-ui/core';


const Styles = theme => ({
    root_grid: {
        display: 'grid',
        gridTemplateRows: '65px auto',
        overflowY: 'visible',
    },
    header: {
        zIndex: 999999,
        maxHeight: '65px',
    },
    inner_grid: {
        display: 'grid',
        gridTemplateColumns: '155px auto',
        overflowY: 'visible',
    },
});

class ProjectHomeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: queryString.parse(this.props.location.search).code,
            loading: false,
            error: false,
            offline: false,
            projects_data: [],
        };
        this.fetchData = this.fetchData.bind()
    }

    fetchData = async () => {
        try {
            this.setState({
                ...this.state,
                loading: false,
            })
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }
    
    componentDidMount() {
        this.fetchData();
    }

    render() {
        const { classes } = this.props;        

        return (
            <>
                <div className={classes.root_grid}>
                    <div className={classes.header}>
                        <Header 
                            // project_name={}
                        />
                    </div>
                    <div>
                        {this.state.offline ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                You are offline!
                            </div>
                        ) : this.state.error ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                                    <strong>An error occured!</strong>
                                </Typography>
                                <center>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => window.location.reload()}
                                    >
                                        <Replay /> Try again
                                    </Button>
                                </center>
                            </div>
                        ) : (      
                                <div className={classes.inner_grid}>
                                    <div><Sidebar /></div>
                                    <div  className={classes.main}>
                                        <ProjectHome 
                                            loading={this.state.loading}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    <div>
                    </div>                
                </div>

            </>
        );
    }
}

export default withAuth0(withStyles(Styles)(ProjectHomeContainer));
