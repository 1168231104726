import React, { Component } from 'react';
import Axios from 'axios';
import _, { filter } from 'lodash';
import { API_URL } from '../../utils/config/config';
import { withAuth0 } from "@auth0/auth0-react";
import queryString from 'query-string'
import Header from '../../components/post-security/common/header';
import Sidebar from '../../components/post-security/common/sidebar';
import Projects from '../../components/post-security/projects_view/projects';
import { FRONT_END_URL } from '../../utils/config/config';

import {Typography, Button, Paper, Dialog, withWidth} from '@material-ui/core';
import { Replay } from '@mui/icons-material';

import { withStyles } from '@material-ui/core';

const Styles = theme => ({
    root_grid: {
        display: 'grid',
        gridTemplateRows: '65px auto',
        overflowY: 'visible',
    },
    header: {
        zIndex: 999999,
        maxHeight: '65px',
    },
    inner_grid: {
        display: 'grid',
        gridTemplateColumns: '155px auto',
        overflowY: 'visible',
    },
});

class ProjectsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // code: this.props.match.params,
            code: queryString.parse(this.props.location.search).code,
            loading: true,
            error: false,
            error_code: null,
            offline: false,
            projects_data: [],
        };
        this.fetchData = this.fetchData.bind()
        this.changeActiveProject = this.changeActiveProject.bind()
    }

    fetchData = async () => {
        try {
            const api_header = {
                'content-type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            }
    
            const response = await Axios.get(`${API_URL}fetch_projects/${localStorage.getItem('user_id')}/`, {
                headers: api_header,
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });
            this.setState({
                ...this.state,
                loading: false,
                projects_data: response.data.data,
            })

        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }
    
    sendAuthToken = async () => {
        const response = await Axios.post(`${API_URL}procore_auth/`, 
            {
                code: this.state.code
            },
            {
                headers: {
                    'content-type': 'application/json',
                },
            }
        );
        if (response.status === 405) {
            // User not subscribed to Wrap Up
            this.setState({error_code: 405})
        } else if (response.status === 400) {
            // Authentication Failed
            window.open(FRONT_END_URL,'_self');
        } else if (response.data.valid) {
            localStorage.setItem('user_id', response.data.user_id)
            localStorage.setItem('user_email', response.data.user_email)
            localStorage.setItem('user_procore_id', response.data.user_procore_id)    
            localStorage.setItem('access_token', response.data.access_token)  
            window.open(FRONT_END_URL+'/projects','_self')
            this.fetchData() 
        } else {
            this.setState({error: true})
        }
    }

    changeActiveProject = async (project_id) => {
        const response = await Axios.get(`${API_URL}change_active_project/${localStorage.getItem('user_id')}/${project_id}/`, 
            {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            }
        );
        if (response.status === 400) {
            // Authentication Failed
            this.props.history.push('/');
        }
        if (response.data.valid) {
                localStorage.setItem('active_project',project_id)
                this.props.history.push('/project_home/')
            } else {
                this.setState({error: true})
            }
    }

    componentDidMount() {
        if (this.state.code) {
            // Auth Token from Procore received.
            // Making the API call to backend which will trade this token for Access Token
            this.sendAuthToken()
        } else {
            this.fetchData();
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <>
                <div className={classes.root_grid}>
                    <div className={classes.header}>
                        <Header />
                    </div>
                    <div>
                        {this.state.offline ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                You are offline!
                            </div>
                        ) : this.state.error ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                                    <strong>An error occured!</strong>
                                </Typography>
                                <center>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => window.location.reload()}
                                    >
                                        <Replay /> Try again
                                    </Button>
                                </center>
                            </div>
                        ) : this.state.error_code ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                                    <strong>You are not subscribed to Wrap Up</strong>
                                </Typography>
                                <center>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => window.open(FRONT_END_URL+'/#info','_self')}
                                    >
                                        <Replay /> Contact Us
                                    </Button>
                                </center>
                            </div>
                        ) : (      
                                <div className={classes.inner_grid}>
                                    <div><Sidebar /></div>
                                    <div className={classes.main}>
                                        <Projects 
                                            loading={this.state.loading}
                                            projects_data={this.state.projects_data}
                                            changeActiveProject={this.changeActiveProject}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    <div>
                    </div>                
                </div>

            </>
        );
    }
}

export default withAuth0(withStyles(Styles)(ProjectsContainer));
