import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper, LinearProgress } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@mui/lab';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider } from '@material-ui/core';
import SearchBar from "material-ui-search-bar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { FRONT_END_URL } from '../../../utils/config/config';
import { TypeAnimation } from 'react-type-animation';
import { COLOR_THEME } from '../common/constants'

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	topblock: {
		display: 'flex'
	},
    divider: {
        height: '2px',
        backgroundColor: 'black'
    },
    customProgressBar: {
        '& .MuiLinearProgress-bar': {
          transition: 'width 15s', // Adjust the transition duration
        },
    },
}));

//Function that pulls the data and sends it to the Table Renderer
const ProjectWrapup = props => {
    let {loading, exportZip, ws_messages, export_active } = props;
    const classes = useStyles();

    const [exportDisabled, setExportDisabled] = useState(false);
    const handleExport = () => {

        exportZip()
        setExportDisabled(true);
        setTimeout(() => {
            setExportDisabled(false);
          }, 9000);        
    }

    return (
        <>
          {loading ? (
            <>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                Loading...
              </div>
            </>
            ) : (
            <div className={classes.content}>
				<div className={classes.topblock}>
					<div>
						<Typography variant="h4">Project Wrap Up</Typography>
						{/* <Typography variant="h6">Invite your project owners to setup WrapUp account (coming soon) and access closeout documentation</Typography> */}
						<Typography variant="h6">Access your closeout documentation</Typography>
					</div>
				</div>
				<br />
                {/* <Divider className={classes.divider}/>
				<br /> */}
				{/* <div style={{display: 'flex'}}>
					<div>
                        <Typography variant="h5">Invitations</Typography>
                    </div>
					<div style={{marginLeft: 'auto'}}>
                            <Button variant="contained" onClick={() => exportZip()} style={{backgroundColor: COLOR_THEME.classic_blue, color: 'white'}} 
                            >
                                Invite
                            </Button>
					</div>
				</div>
				<br />
				<br />
				<br />
				<br />
				<br /> */}
                <Divider className={classes.divider}/>
				<br />
				<div style={{display: 'flex'}}>
					<div>
                        <Typography variant="h5">Download Zip</Typography>
                        <Typography variant="subtitle1">Create a turnover package for your project. We will wrap it up nicely into a zip file, organized into folders along with a table of contents.</Typography>
                    </div>
					<div style={{marginLeft: 'auto'}}>
                            <Button 
                                variant="contained" 
                                onClick={() => handleExport()} 
                                style={{backgroundColor: exportDisabled ? 'gray' : COLOR_THEME.classic_blue, color: 'white'}} 
                                disabled={exportDisabled}
                            >
                                Export
                            </Button>
					</div>
				</div>
                {export_active && (
                        <div>
                            <br /><br /><br />
                            <LinearProgress className={classes.customProgressBar}/>
                            {ws_messages.map((message, index) => (
                                <div key={index}>
                                    <br />
                                    <TypeAnimation
                                        sequence={[message]}
                                        wrapper="span"
                                        cursor={false}
                                        repeat={0}
                                        speed={90}
                                        style={{ fontSize: '1.2em', display: 'inline-block' }}
                                    />
                                </div>
                            ))}
                            <br/>
                        </div>
                    )}

            </div>
          )}
        </>
    );
};




export default ProjectWrapup;