import React, { Component } from 'react';
import Axios from 'axios';
import _, { filter } from 'lodash';

import { HOST } from '../../utils/config/config';
import { withAuth0 } from "@auth0/auth0-react";

import Header from '../../components/post-security/common/header';
import Sidebar from '../../components/post-security/common/sidebar';
import ProjectWrapup from '../../components/post-security/project_wrapup/project_wrapup';

import {Typography, Button, Paper, Dialog, withWidth} from '@material-ui/core';
import { Replay } from '@mui/icons-material';
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@mui/lab/Alert';

import { withStyles } from '@material-ui/core';

const Styles = theme => ({
    root_grid: {
        display: 'grid',
        gridTemplateRows: '65px auto',
        overflowY: 'visible',
    },
    header: {
        zIndex: 999999,
        maxHeight: '65px',
    },
    inner_grid: {
        display: 'grid',
        gridTemplateColumns: '155px auto',
        overflowY: 'visible',
    },
});

class ProjectWrapupContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            snackbar_show: false,
            snackbar_message: '',
            snackbar_type: 'info',
            loading: false,
            error: false,
            offline: false,
            ws_messages: [],
            export_active: false,
            // directory_data: [],
        };
        this.exportZip = this.exportZip.bind()
    }

    fetchData = async () => {
        try {
            this.setState({
                ...this.state,
                loading: false,
            })
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }
    
    exportZip = async () => {

        let address = `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${HOST}ws/zipexport/?user=${localStorage.getItem('user_id')}&Bearer=${localStorage.getItem('access_token')}`
        const socket = new WebSocket(address);
        socket.onopen = (event) => {
            this.setState({ws_messages: []})
            this.setState({export_active: true});
        };

        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.message.includes('https://')) {
                window.open(message.message, '_blank');
                this.setState({export_active: false});
            } else {
                this.setState({ws_messages: [...this.state.ws_messages, message.message]})
            }
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {  

        const { classes } = this.props;        
        return (
            <>
                <div className={classes.root_grid}>
                    <div className={classes.header}>
                        <Header />
                    </div>
                    <div>
                        {this.state.offline ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                You are offline!
                            </div>
                        ) : this.state.error ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                                    <strong>An error occured!</strong>
                                </Typography>
                                <center>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => window.location.reload()}
                                    >
                                        <Replay /> Try again
                                    </Button>
                                </center>
                            </div>
                        ) : (      
                                <div className={classes.inner_grid}>
                                    <Snackbar 
                                        anchorOrigin={{ vertical: 'bottom',horizontal: 'center'}}
                                        open={this.state.snackbar_show}
                                        autoHideDuration={3000}
                                        onClose={() => this.setState({snackbar_show: false})}
                                    >
                                        <MuiAlert elevation={6} variant="filled" severity={this.state.snackbar_type}>
                                            {this.state.snackbar_message}
                                        </MuiAlert>
                                    </Snackbar>
                                    <div><Sidebar /></div>
                                    <div  className={classes.main}>
                                        <ProjectWrapup 
                                            loading={this.state.loading}
                                            exportZip={this.exportZip}
                                            ws_messages={this.state.ws_messages}
                                            export_active={this.state.export_active}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    <div>
                    </div>                
                </div>

            </>
        );
    }
}

export default withAuth0(withStyles(Styles)(ProjectWrapupContainer));
