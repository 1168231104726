import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper, Radio, RadioGroup, FormControlLabel, TextField } from '@material-ui/core';
import { Link } from "react-router-dom";
import { COLOR_THEME } from '../../components/post-security/common/constants'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        minHeight: '100vh',
        background: 'linear-gradient(90deg, #1CB5E0 0%, #000851 100%), #F18D2B',
        mixBlendMode: 'normal',
        position: 'relative',
        height: '100%',
    },
    
    textWrapper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#fff',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    text1: {
        fontFamily: 'Roboto',
        fontWeight: 200,
        fontStyle: 'normal',
        fontSize: '60px',
        lineHeight: '60px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '42px',
        },
        paddingLeft: '1rem',
    },
    text2: {
        fontFamily: 'Roboto',
        fontWeight: 100,
        fontSize: '35px',
        textAlign: 'center',
        paddingBottom: '3px',
    },
    icon: {
        display: 'inline-block',
        verticalAlign: 'middle',
        fontSize: '20px',
    },
    paper: {
        display: 'block',
        padding: '20px'
    },
    submitButton: {
        color: 'white', 
        backgroundColor: COLOR_THEME.procore_orange, 
        '&:disabled' : {backgroundColor: 'pink'}
    }
}));

const ContactPage = (props) => {
    let {submitMessage } = props;
    const classes = useStyles();
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const [message, SetMessage] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {
        let error_message = ''
        let email_error = /\S+@\S+\.\S+/.test(email) ? false : true
        let number_error = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/.test(number) && number.length===10 ? false : true
        let message_error = message === '' ? true : false
        if (email_error||number_error||message_error) {
            setError(true)
        } else {
            setError(false)
        }
    }, [email, number, message])

    return (
        <div className={classes.root}>
            <div className={classes.textWrapper}>
                <>
                    <Typography variant='h4' className={classes.text2}>
                    Schedule a demo of Wrap Up.

                    </Typography>
                    <br />
                    <Paper className={classes.paper}>
                        <TextField
                            error={/\S+@\S+\.\S+/.test(email) ? false : true}
                            label="Email" variant="standard" 
                            style={{minWidth:'100%', border: '1px black solid'}}
                            // placeholder="Enter your Email here..."
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <br />
                        <br />
                        <TextField
                            error={/^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/.test(number) && number.length===10 ? false : true}
                            label="Contact Number" variant="standard" 
                            style={{minWidth:'100%', border: '1px black solid'}}
                            // placeholder="Enter your Email here..."
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                        />
                        <br />
                        <br />
                        <TextField
                            error ={message==='' ? true: false} 
                            label="Message" variant="standard" 
                            style={{minWidth:'100%', border: '1px black solid'}}
                            multiline
                            minRows={4}
                            // placeholder="Write your message here..."
                            value={message}
                            onChange={(e) => SetMessage(e.target.value)}
                        />
                    </Paper>
                    <br />
                    <Button 
                        variant="contained" className={classes.submitButton}
                        onClick={() => submitMessage(email, number, message)}
                        disabled={error ? true : false}
                    >
                        Submit
                    </Button>
                </>
            </div>
        </div>
    );
};

export default ContactPage;
