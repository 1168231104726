import React, { Component } from 'react';
import Axios from 'axios';
import _, { filter } from 'lodash';

import { API_URL } from '../../utils/config/config';
import { withAuth0 } from "@auth0/auth0-react";

import Header from '../../components/post-security/common/header';
import Sidebar from '../../components/post-security/common/sidebar';
import ProjectDirectorySetup from '../../components/post-security/project_directory/project_directory_setup';

import {Typography, Button, Paper, Dialog, withWidth} from '@material-ui/core';
import { Replay } from '@mui/icons-material';

import { withStyles } from '@material-ui/core';

const Styles = theme => ({
    root_grid: {
        display: 'grid',
        gridTemplateRows: '65px auto',
        overflowY: 'visible',
    },
    header: {
        zIndex: 999999,
        maxHeight: '65px',
    },
    inner_grid: {
        display: 'grid',
        gridTemplateColumns: '155px auto',
        overflowY: 'visible',
    },
});

class ProjectDirectorySetupContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            offline: false,
            procore_data: [],
            existing_directory: {},
            subs_with_commitments: [],
        };
        this.saveDirectoryData = this.saveDirectoryData.bind()
    }

    fetchData = async () => {
        try {
            const response = await Axios.get(`${API_URL}project_directory_setup/${localStorage.getItem('user_id')}/`,
            {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });

            this.setState({
                loading: false,
                procore_data: response.data.procore_data,
                existing_directory: response.data.existing_directory,
                subs_with_commitments: response.data.subs_with_commitments
            })
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    saveDirectoryData = async (data, mode) => {
        try {
            const response = await Axios.post(`${API_URL}project_directory_update/${localStorage.getItem('user_id')}/`,
            data,
            {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });

            if (response.data.valid) {
                if (mode === 'new') {
                    this.props.history.push('/new_project_setup/2')
                } else {
                    this.props.history.push('/project_directory')
                }
            }
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {  

        const { classes } = this.props;        
        return (
            <>
                <div className={classes.root_grid}>
                    <div className={classes.header}>
                        <Header />
                    </div>
                    <div>
                        {this.state.offline ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                You are offline!
                            </div>
                        ) : this.state.error ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                                    <strong>An error occured!</strong>
                                </Typography>
                                <center>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => window.location.reload()}
                                    >
                                        <Replay /> Try again
                                    </Button>
                                </center>
                            </div>
                        ) : (
                            <div className={classes.inner_grid}>
                                <div><Sidebar /></div>
                                <div  className={classes.main}>
                                    <ProjectDirectorySetup 
                                        loading={this.state.loading}
                                        procore_data={this.state.procore_data}
                                        saveDirectoryData={this.saveDirectoryData}
                                        existing_directory={this.state.existing_directory}
                                        subs_with_commitments={this.state.subs_with_commitments}
                                    />
                                </div>
                            </div>
                        )}
                        </div>
                    <div>
                    </div>                
                </div>

            </>
        );
    }
}

export default withAuth0(withStyles(Styles)(ProjectDirectorySetupContainer));
