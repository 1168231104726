// BackEnd Details

// Development Mode
// export const API_HOST = process.env.APP_HOST || 'localhost';
// export const API_PORT = process.env.APP_PORT || 8000;
// export const HOST = `${API_HOST}:${API_PORT}/`;
// export const apiPath = 'api/v1/';
// export const API_URL = `http://${HOST}${apiPath}`;
// export const FRONT_END_URL = `http://localhost:3000`;
// export const REACT_APP_PROCORE_DOMAIN = 'sandbox.procore.com'

//Production Mode
export const API_HOST = 'api.wrapup.build/';
// export const API_HOST = 'wrapup-be.herokuapp.com/';
// export const API_HOST = 'wrapup-be2.herokuapp.com/';

export const HOST = `${API_HOST}`;
export const apiPath = 'api/v1/';
export const API_URL = `https://${HOST}${apiPath}`;
export const FRONT_END_URL = `https://wrapup.build`;
export const REACT_APP_PROCORE_DOMAIN = 'login.procore.com'

export const REACT_APP_REDIRECT_URI = FRONT_END_URL + '/projects/';
