import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper, TextField } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { COLOR_THEME } from '../common/constants'
import Checkbox from "@mui/material/Checkbox";
import PersonIcon from '@mui/icons-material/Person';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import Navigation from './navigation';

const steps = [
    "Select Companies",
    "Send Message"
];

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	type_option: {
		display: 'flex',
        '&:hover': {
            border: '1px solid #093D61',
        }
	},
    nextButton: {
        color: 'white', 
        backgroundColor: COLOR_THEME.classic_blue,        
        "& .Mui-disabled": {
            backgroundColor: 'gray',
            }
    },
}));
 
const RemindCompaniesDialog = props => {
    let { showRemindDocumentsDialog, setShowRemindDocumentsDialog, requirement_list,remindCompanies } = props;
    const classes = useStyles();

    // rows
    const [remindRows, setRemindRows] = useState([])
    const [selectedReminderRequirements, setSelectedReminderRequirements] = useState([]);

    useEffect(() => {
        // Essentially part of dialog reset. Making sure when you reopen dialog box, it's fresh.
        setRemindRows(requirement_list.filter(e => e.requirements_data.map(k => k.status).includes('Requested')))
        setSelectedReminderRequirements(remindRows.map(e => e.company_id))
    },[requirement_list])

    // Navigator
    const [activeStep, setActiveStep] = useState(0);
    const ChangeStep = (action) => {
        if (action==='next') {
            setActiveStep(activeStep+1)
            if (activeStep === 1) {
                // Request the documents from Subs               
                remindCompanies(
                    {
                        'companies': selectedReminderRequirements, 
                        'message': message
                    }
                )
                // Resetting state variables
                resetDialog()
            }
        } else if (action==='back') {
            activeStep === 0 ? resetDialog() : setActiveStep(activeStep-1)
        } 
    }

    const resetDialog = () => {
        // Resetting state variables
        setShowRemindDocumentsDialog(false)
        setActiveStep(0)
        setMessage('This is a reminder email to submit your closeout documents.')
    }

    // Step 1
    const isSelected = (company_id) => selectedReminderRequirements.indexOf(company_id) !== -1;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelected = remindRows.map((n) => n.company_id);
          setSelectedReminderRequirements(newSelected);
          return;
        }
        setSelectedReminderRequirements([]);
      };
    
    const handleCheckboxClick = (event, id) => {
        const selectedIndex = selectedReminderRequirements.indexOf(id);
        let newSelected = [];
   
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedReminderRequirements, id);
            } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedReminderRequirements.slice(1));
            } else if (selectedIndex === selectedReminderRequirements.length - 1) {
            newSelected = newSelected.concat(selectedReminderRequirements.slice(0, -1));
            } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedReminderRequirements.slice(0, selectedIndex),
                selectedReminderRequirements.slice(selectedIndex + 1)
            );
        }      
        setSelectedReminderRequirements(newSelected);
      };

    // Step 2:
    const [message, setMessage] = useState('This is a reminder email to submit your closeout documents.')
    const changeText = (e) => setMessage(e.target.value)

    return (
        <div>
            <Dialog
                open={showRemindDocumentsDialog}
                onClose={() => resetDialog()}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Send Reminders</DialogTitle>
                <DialogContent>
                        <>
                            <Navigation step={activeStep} steps={steps}/>
                            <br /> <br/>
                            {(activeStep === 0) && (
                                <div>
                                    <Typography variant='h5'>{selectedReminderRequirements.length} of {remindRows.length} Company Selected</Typography> 
                                    <Typography variant='subtitle1'>Select all the companies to send a reminder email.</Typography> 
                                    <br />
                                    <div>
                                        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                                            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                checked={remindRows.length > 0 && selectedReminderRequirements.length === remindRows.length}
                                                                onChange={handleSelectAllClick}
                                                                inputProps={{"aria-label": "select all"}}
                                                            />
                                                        </TableCell>
                                                        <TableCell>Subcontractor</TableCell>
                                                        <TableCell>Email Recipients</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {remindRows.map((row, index) => (
                                                            <TableRow
                                                                hover
                                                                onClick={(event) => handleCheckboxClick(event, row.company_id)}
                                                                tabIndex={-1}
                                                                selected={isSelected(row.company_id)}
                                                                key={row.company_id}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell padding="checkbox">
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={isSelected(row.company_id)}
                                                                        inputProps={{
                                                                            "aria-labelledby": `enhanced-table-checkbox-${index}`
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">{row.company_name}</TableCell>
                                                                <TableCell component="th" scope="row">{row.requirements_data[0].company_recipients} {((row.requirements_data[0].company_recipients === 0)||(row.requirements_data[0].company_recipients > 10)) ? (<WarningAmberIcon style={{color: 'red'}} />) : <PersonIcon />}</TableCell>
                                                            </TableRow>
                                                        )
                                                )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            )}
                            {(activeStep === 1) && (
                                <div>
                                    <Typography variant='h6'>To</Typography>
                                    <TextField id="outlined-basic" variant="outlined" disabled size="small" style={{minWidth:'100%'}} multiline
                                        defaultValue={[...new Set(remindRows.filter(e => selectedReminderRequirements.includes(e.company_id)).map(e => e.company_name))].join(', ')}
                                    />
                                    <br /><br />
                                    <Typography variant='h6'>Body</Typography>
                                    <TextField id="outlined-basic" variant="outlined" size="small" style={{minWidth:'100%'}} multiline
                                        value={message} onChange={e => changeText(e)}
                                    />
                                </div>
                            )}
                        </>
                        <br /><br />
                        {activeStep !== 1 && <Typography variant='subtitle2'>Clicking "Next" will take you to the next step, and not send the email to the subs just yet.</Typography>}
                </DialogContent>
                <DialogActions style={{padding: '15px', paddingRight: '25px', paddingBottom: '25px'}}>
                    <Button onClick={() => ChangeStep('back')}>{activeStep === 0 ? 'Cancel' : 'Back'}</Button>
                    <Button onClick={() => ChangeStep('next')} autoFocus variant="contained" className={classes.nextButton}
                        disabled={(selectedReminderRequirements.length>0) ? false:true} 
                    >
                        {activeStep !== 1 ? 'Next' : 'Send Reminder Emails'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};


export default RemindCompaniesDialog;