import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom';

import { COLOR_THEME } from '../common/constants'
import { FRONT_END_URL } from '../../../utils/config/config';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    toolbar: {
        maxHeight: '65px',
    },
    title: {
        flexGrow: 0.5,
        display: 'inline',
    },
    text: {
        color: 'white',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    active: {
        // backgroundColor: '#d8d8d8',
        borderRadius: '3px',
    },
    button: {
        padding: theme.spacing(0, 2),
        color: 'white',
    },
    sectionDesktop: {
        display: 'none',
        color: '#d8d8d8',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        color: '#d8d8d8',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
}));

const Header = () => {
    const classes = useStyles();
    const [activeProjectName, setActiveProjectName] = useState('')
    const [activeProjectNumber, setActiveProjectNumber] = useState('')

    useEffect(() => {
        setActiveProjectName(localStorage.getItem('active_project_name') || '');
        setActiveProjectNumber(localStorage.getItem('active_project_number') || '');
    }, []);

    let hideActiveProjectName = window.location.href === `${FRONT_END_URL}/projects` || window.location.href === `${FRONT_END_URL}/new` ? true : false;
    
    return (
        <div className={classes.root}>
            <AppBar style={{backgroundColor: COLOR_THEME.classic_blue, color: 'white'}} position="sticky">
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h6" className={classes.title}>
                        <NavLink to="/projects" className={classes.text}>
                            Wrap Up
                        </NavLink>
                    </Typography>
                    {activeProjectName && !hideActiveProjectName && (
                        <Typography variant="h6" className={classes.title}>
                            {activeProjectNumber !== 'null' && <>{activeProjectNumber}&ensp;</>}
                            {activeProjectName}
                        </Typography>  
                    )}
                    {/* <div className={classes.sectionDesktop}>
                        <Link to={{ pathname:`https://forms.clickup.com/f/a24hy-217/ZJ2C29L9JE1AD6V0OZ?App%20Origin=Wrap Up&Email=${localStorage.getItem('email')}` }} target='_blank' className={classes.text}>
                            <Button className={classes.button} color="default">
                                User
                            </Button>
                        </Link>
                    </div> */}
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Header;
