import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper, TextField } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import { COLOR_THEME } from '../common/constants'
import Checkbox from "@mui/material/Checkbox";
import {useDropzone} from 'react-dropzone';
import ImageIcon from '@mui/icons-material/Image';

import Navigation from './navigation';
import Autocomplete from '@mui/material/Autocomplete';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import DescriptionIcon from '@mui/icons-material/Description';
import { active } from 'd3';

const steps = [
    "Type",
    "Details",
];

const options = [
    {
        header: 'Drawings',
        sub: 'As-Builts, Shop Drawings etc.',
        option: 'Drawings',
        icon: <ArchitectureIcon />
    },
    {
        header: 'Maintenance',
        sub: 'O&M Manuals, Preventative Maintenance, Training Videos etc.',
        option: 'Maintenance',
        icon: <HomeRepairServiceIcon />
    },
    {
        header: 'Warranty',
        sub: 'Warranty Documents, Workmanship Warranty etc.',
        option: 'Warranty',
        icon: <WorkspacePremiumIcon />
    },
    {
        header: 'Other Document',
        sub: 'Attic Stock, Installation Certificates etc.',
        option: 'Other',
        icon: <DescriptionIcon />
    },
]

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	type_option: {
		display: 'flex',
        '&:hover': {
            border: '1px solid #093D61',
        }
	},
    nextButton: {
        color: 'white', 
        backgroundColor: COLOR_THEME.classic_blue,        
        "& .Mui-disabled": {
            backgroundColor: 'gray',
            }
    },
    dropzone: {
        textAlign: "center",
        padding: "10px",
        border: "1px #bdbdbd solid",
        backgroundColor: "#fafafa",
        color: "#717171",
        [theme.breakpoints.up('sm')]: {
            padding: "20px",
        },
    }
}));
 
//Function that pulls the data and sends it to the Table Renderer
const NewRequirement = props => {
    let { showDialog, setShowDialog,createRequirementType } = props;
    const classes = useStyles();

    // Step 1
    const [type, setType] = useState(null);

    // Step 2
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({ 
        maxFiles: 1});

    const resetDialog = () => {
        // Resetting state variables
        setShowDialog(false)
        setActiveStep(0)
        setType(null)
        setCategory('')
        setDescription('')
    }

    // Navigator
    const [activeStep, setActiveStep] = useState(0);
    const ChangeStep = (action) => {
        if (action==='next') {
            setActiveStep(activeStep+1)
            if (activeStep === 1) {
                // Create the requirements                
                var requirementFormData = new FormData();
                requirementFormData.append('parent_type', type);
                requirementFormData.append('name', category);
                requirementFormData.append('description', description ? description : '');
                requirementFormData.append('project_id', localStorage.getItem('active_project'));
                if (acceptedFiles.length>0) {
                    requirementFormData.append('sample_file', acceptedFiles[0]);
                }
                createRequirementType(requirementFormData);
                // Resetting state variables
                resetDialog()
            }
        } else if (action==='back') {
            activeStep === 0 ? resetDialog() : setActiveStep(activeStep-1)
        } 
    }

    return (
        <div>
            <Dialog
                open={showDialog}
                onClose={() => resetDialog()}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>New Requirement</DialogTitle>
                <DialogContent>
                    <Navigation step={activeStep} steps={steps}/>
                    <br /> 
                    {/* Step 1 */}
                    {activeStep === 0 && (
                        <div>
                        <Typography variant='h5'>Select the Requirement type</Typography> 
                        <br />
                        <br /> 
                        <Stack spacing={2}>
                            {options.map(option => (
                                <Paper 
                                    className={classes.type_option}
                                    onClick={() => setType(option.option)}
                                    style={{backgroundColor: type === option.option ? COLOR_THEME.selected_blue : 'white'}}
                                    key={option.header}
                                >
                                    <div style={{padding: '15px'}}>
                                        {option.icon}
                                    </div>
                                    <div>
                                        <Typography variant='h6'>{option.header}</Typography>
                                        <Typography variant='subtitle1'>{option.sub}</Typography>
                                    </div>
                                </Paper>
                            ))}
                        </Stack>
                        </div>
                    )}
                    {/* Step 2 */}
                    {activeStep === 1 && (
                        <div>
                        <Typography variant='h5'>Enter the Requirement details</Typography> 
                        <br />
                        <br />
                        <Typography variant='h6'>Parent Type</Typography> 
                        <TextField style={{minWidth: '25vw'}} id="standard-basic" variant="outlined" 
                            value={options.filter(e => e.option === type)[0].header}
                            disabled
                        />
                        <br />
                        <br />
                        <Typography variant='h6'>Type</Typography> 
                        <TextField style={{minWidth: '25vw'}} id="standard-basic" variant="outlined" 
                            value={category}
                            onChange={e => setCategory(e.target.value)}
                            placeholder={'Example: Warranty, Drawings, O&M Manual etc.'}
                        />
                        <br />
                        <br />
                        <Typography variant='h6'>Description (Optional)</Typography> 
                        <TextField style={{minWidth: '25vw'}} id="standard-basic" variant="outlined" 
                            value={description} multiline rows={3}
                            onChange={e => setDescription(e.target.value)}
                            placeholder={'Description'}
                        />
                        <br />
                        <br />
                        <Typography variant='h6'>Sample File (Optional)</Typography> 
                        <section>
                            <div {...getRootProps({className: classes.dropzone})}>
                                <input {...getInputProps()} />
                                <Typography variant='subtitle1'>Drag 'n' drop or click to select an image file</Typography>
                            </div>
                            <br />
                            {acceptedFiles.length > 0 && (
                                <div style={{display: 'flex', alignItems: 'center', flexWrap:'wrap'}}>
                                    <Typography variant='subtitle1'>New Selected File:</Typography>&nbsp;
                                    <Chip 
                                        icon={<ImageIcon />} 
                                        label={acceptedFiles[0].path.substring(0,25)} 
                                        color='primary'
                                        size='small'
                                    />
                                </div>
                            )}
                        </section>
                        <Typography variant='subtitle2'>Note: This sample file will be accessible to any person with the link to upload files. We recommend that you don't add any confidential/sensitive information in the sample file.</Typography> 
                        <br />
                        <br />
                        </div>
                    )}
                    <br />
                </DialogContent>
                <DialogActions style={{padding: '15px', paddingRight: '25px', paddingBottom: '25px'}}>
                    <Button onClick={() => ChangeStep('back')}>{activeStep === 0 ? 'Cancel' : 'Back'}</Button>
                    <Button onClick={() => ChangeStep('next')} autoFocus variant="contained" className={classes.nextButton}
                        disabled={((activeStep===0) && type)||((activeStep===1) && category !== '') ? false:true} 
                    >
                        {activeStep !== 1 ? 'Next' : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};


export default NewRequirement;