import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../../utils/config/config';
import { withAuth0 } from "@auth0/auth0-react";
import queryString from 'query-string'

import { withStyles } from '@material-ui/core';

const Styles = theme => ({
    root_grid: {
        display: 'grid',
        gridTemplateRows: '65px auto',
        overflowY: 'visible',
    },
    header: {
        zIndex: 999999,
        maxHeight: '65px',
    },
    inner_grid: {
        display: 'grid',
        gridTemplateColumns: '155px auto',
        overflowY: 'visible',
    },
});

// This url basically just changes the active project and redirects to the project requirements page
// It also changes the requirement view and tab to show List view with Submitted tab.

class SubmissionNotificationContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // code: this.props.match.params,
            code: queryString.parse(this.props.location.search).code,
            loading: true,
            error: false,
            error_code: null,
            offline: false,
            projects_data: [],
        };
        this.changeActiveProject = this.changeActiveProject.bind()
    }

    changeActiveProject = async (project_id,company_id) => {
        const response = await Axios.get(`${API_URL}change_active_project/${localStorage.getItem('user_id')}/${project_id}/`, 
            {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            }
        );
        if (response.status === 400) {
            // Authentication Failed
            this.props.history.push('/');
        }
        if (response.data.valid) {
                localStorage.setItem('active_project',project_id)
                localStorage.setItem('requirements_tab','Submitted')
                localStorage.setItem('requirements_view',1)
                this.props.history.push('/project_requirements/'+company_id)
            } else {
                this.setState({error: true})
            }
    }

    componentDidMount() {
        let { project,company } = this.props.match.params;
        if (project && company) {
            this.changeActiveProject(project,company)
        } else {
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <>
            </>
        );
    }
}

export default withAuth0(withStyles(Styles)(SubmissionNotificationContainer));
