import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@mui/lab';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Switch from '@mui/material/Switch';
import SearchBar from "material-ui-search-bar";
import { FRONT_END_URL } from '../../../utils/config/config';
import Box from "@mui/material/Box";
import Stack from '@mui/material/Stack';
import GradingIcon from '@mui/icons-material/Grading';
import QuizIcon from '@mui/icons-material/Quiz';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import { COLOR_THEME } from '../common/constants'

import SelectSubmittalsDialog from './submittal_selector';
import SelectRFIsDialog from './rfi_selector'


const options = [
    {
        header: 'Submittals',
        // sub: 'As-Builts, Shop Drawings etc.',
        option: 'sync_submittals',
        icon: <GradingIcon />,
        disabled: false
    },
    {
        header: 'RFIs',
        // sub: 'O&M Manuals, Preventative Maintenance, Training Videos etc.',
        option: 'sync_rfis',
        icon: <QuizIcon />,
        disabled: false
    },
    // {
    //     header: 'Photos',
    //     // sub: 'O&M Manuals, Preventative Maintenance, Training Videos etc.',
    //     option: 'sync_photos',
    //     icon: <PhotoLibraryIcon />,
    //     disabled: true
    // },
    // {
    //     header: 'Drawings',
    //     // sub: 'O&M Manuals, Preventative Maintenance, Training Videos etc.',
    //     option: 'sync_drawings',
    //     icon: <ViewSidebarIcon />,
    //     disabled: true
    // },
    // {
    //     header: 'Punchlist',
    //     // sub: 'O&M Manuals, Preventative Maintenance, Training Videos etc.',
    //     option: 'sync_punchlist',
    //     icon: <FormatListNumberedIcon />,
    //     disabled: true        
    // },
    // {
    //     header: 'Inspections',
    //     // sub: 'O&M Manuals, Preventative Maintenance, Training Videos etc.',
    //     option: 'sync_inspections',
    //     icon: <FindInPageIcon />,
    //     disabled: true
    // },
]


// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	topblock: {
		display: 'flex',
        padding: '0px',
        paddingTop: '0px'
	},
    stackblock: {
        marginTop: '-50px',
        paddingTop: '0px'
    },
    type_option: {
        padding: '10px',
        maxWidth: '50vw',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            border: '1px solid #093D61',
        },
    },
    selectButton: {
        color: 'white', 
        backgroundColor: COLOR_THEME.classic_blue,        
        },
}));
 
//Function that pulls the data and sends it to the Table Renderer
const ProcoreSyncs = props => {
    let {loading, procore_sync_data, updateData, fetchSubmittalsData, fetchRFIData, procore_sync_submittal_data, procore_sync_rfi_data, existing_submittal_selection, existing_rfi_selection, saveSubmittalsSelection, saveRFISelection } = props;
    const classes = useStyles();
    // Select Submittals
    const [showSelectSubmittalsDialog, setShowSelectSubmittalsDialog] = useState(false);
    // Select RFIs
    const [showSelectRFIsDialog, setShowSelectRFIsDialog] = useState(false);

    return (
        <>
          {loading ? (
            <>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                Loading...
              </div>
            </>
            ) : (
            <div className={classes.content}>
				<div className={classes.topblock}>
					<div>
						<Typography variant="h4">Procore Syncs</Typography>
						<Typography variant="h6">Turn on the syncs to include the documents from Procore in the zip export</Typography>
					</div>
				</div>

                <div className={classes.topblock}>
                    <SelectSubmittalsDialog
                        loading={loading}
                        showSelectSubmittalsDialog={showSelectSubmittalsDialog}
                        setShowSelectSubmittalsDialog={setShowSelectSubmittalsDialog}
                        procore_sync_submittal_data={procore_sync_submittal_data}
                        existing_submittal_selection={existing_submittal_selection}
                        saveSubmittalsSelection={saveSubmittalsSelection}
                    />
                    <SelectRFIsDialog
                        loading={loading}
                        showSelectRFIsDialog={showSelectRFIsDialog}
                        setShowSelectRFIsDialog={setShowSelectRFIsDialog}
                        procore_sync_rfi_data={procore_sync_rfi_data}
                        existing_rfi_selection={existing_rfi_selection}
                        saveRFISelection={saveRFISelection}
                    />
                </div>

                <div className={classes.stackblock}>
                    <Stack spacing={2}>
                        {options.map(option => (
                            <Paper 
                                key={option.header}
                                className={classes.type_option}
                                // onClick={() => setType(option.option)}
                                // style={{backgroundColor: type === option.option ? COLOR_THEME.selected_blue : 'white'}}
                            >
                                <div style={{padding: '15px'}}>
                                    {option.icon}
                                </div>
                                <div>
                                    <Typography variant='h6'>{option.header}</Typography>
                                </div>
                                <div style={{marginLeft:'auto'}}>
                                    {option.disabled ? (
                                        <Typography variant='subtitle1'>Coming soon...</Typography>
                                    ):(
                                        <div style={{display: 'flex'}}>
                                            <div>
                                                {option.header === "Submittals" && procore_sync_data[option.option] && (
                                                    <>
                                                        <Button className={classes.selectButton} onClick={() => {
                                                            fetchSubmittalsData()
                                                            setShowSelectSubmittalsDialog(true)
                                                        }}>
                                                            Select Submittals
                                                        </Button>
                                                    </>
                                                )}
                                                </div>
                                                <div>
                                                {option.header === "RFIs" && procore_sync_data[option.option] && (
                                                    <>
                                                        <Button className={classes.selectButton} onClick={() => {
                                                            fetchRFIData()
                                                            setShowSelectRFIsDialog(true)
                                                        }}>
                                                            Select RFIs
                                                        </Button>
                                                    </>
                                                )}&ensp;&ensp;&ensp;&ensp;&ensp;
                                                </div>
                                            <div>
                                            Off
                                            <Switch 
                                                checked={procore_sync_data[option.option]}
                                                onChange={(e) => {
                                                    let body = {
                                                        [option.option] : e.target.checked
                                                    }
                                                    updateData(body)
                                                }}
                                                disabled={option.disabled}
                                            />
                                            On
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Paper>
                        ))}
                    </Stack>
                </div>
            </div>
          )}
        </>
    );
};


export default ProcoreSyncs;