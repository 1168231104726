import React, { Component } from 'react';
import { hot, setConfig } from 'react-hot-loader';

// Import custom components
import MainRouter from '../../routers/routes';
import { withAuth0 } from "@auth0/auth0-react";
import { local } from 'd3';

import LogRocket from 'logrocket';
if (localStorage.getItem('user_email')) {
    LogRocket.init('pjej7o/wrap-up');
    LogRocket.identify(localStorage.getItem('user_email'), {
        id: localStorage.getItem('user_id'),
        email: localStorage.getItem('user_email'),
    })
}

// Hiding an error in console log.
// https://stackoverflow.com/questions/54770535/react-hot-loader-react-dom-patch-is-not-detected
setConfig({
    showReactDomPatchNotification: false
})
// require('dotenv').config()

class AppContainer extends Component {

    render() {
        return (<MainRouter />);
    }
}

export default withAuth0(hot(module)(AppContainer));
