import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper } from '@material-ui/core';

import { COLOR_THEME } from '../common/constants'

import Navigation from './navigation'
import ProjectSelector from './project_selector'
import TeamBuilder from './team_builder'
import loading_gif from '../common/loading.gif'

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	topblock: {
		display: 'flex'
	},
	mainblock: {
		minHeight: '50vh',
	}
}));
 
//Function that pulls the data and sends it to the Table Renderer
const NewProject = props => {
    let {loading, active_projects_list, pushCreateProject } = props;
    const [selectedProject, setSelectedProject] = useState({})

    const classes = useStyles();
    // Step goes from 0 > 1
    const [step, setStep] = useState(0);

    const CreateProject = () => {
        setStep(step+1)
        let body = {
            project: selectedProject,
        }
        pushCreateProject(body)
    }

    return (
        <>
          {loading ? (
            <>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <img src={loading_gif} style={{height: '200px', display: 'block', margin: 'auto'}}/>
                <Typography variant='h6'>
                    Please wait while we pull the list of your Projects from Procore...
                </Typography>
              </div>
            </>
            ) : (
            <div className={classes.content}>
				<div className={classes.topblock}>
					<div>
						<Typography variant="h4">Add a Project</Typography>
					</div>
				</div>
                <div>
                        <br />
						<Typography variant="h6">If the project already exists, we'll add you to it!</Typography>
					</div>
				<br />
				{/* <div>
                    <Navigation step={step}/>
				</div>
				<br /> */}
				<br />
				<br />
				<div className={classes.mainblock}>
                    {step === 0 && (
                        <div>
                            <ProjectSelector 
                                active_projects_list={active_projects_list}
                                setSelectedProject={setSelectedProject}
                            />
                        </div>
                    )}
				</div>
                <br /><br />
                <div style={{display: 'flex', justifyContent:'flex-end'}}>
                    <>
                        <Button
                            variant="contained" 
                            style={{color: 'white', backgroundColor: COLOR_THEME.classic_blue}}
                            disabled={(Object.keys(selectedProject).length < 1) ? true : false}
                            onClick={() => CreateProject()}
                        >
                            Create
                        </Button>
                    </>
                </div>
            </div>
          )}
        </>
    );
};


export default NewProject;