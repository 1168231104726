import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { TableSortLabel } from '@mui/material';
import TextField from '@mui/material/TextField';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import PersonIcon from '@mui/icons-material/Person';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import EditCompanySubmitters from '../requirements_view/edit_company_submitters';
import NotesIcon from '@mui/icons-material/Notes';
import { COLOR_THEME } from '../common/constants'
import { set } from 'lodash';

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	topblock: {
		display: 'flex'
	},
    approveButton: {
        color: 'white', 
        backgroundColor: '#198754',        
        "& .Mui-disabled": {
            backgroundColor: 'gray',
            }
    },
    rejectButton: {
        color: '#dc3545', 
        border: '#dc3545 1px solid',        
        "& .Mui-disabled": {
            backgroundColor: 'gray',
            }
    },
}));
 
//Function that pulls the data and sends it to the Table Renderer
const ProjectDirectory = props => {
    let {loading, directory_data, company_directory, procore_users, fetchSubmitterData, saveSubmittersData, updateNotes } = props;
    const classes = useStyles();

    const [rows,setRows] = useState(directory_data)
    useEffect(() => {
        setRows(directory_data)
    },[directory_data])

    const [displayNotes, setDisplayNotes] = useState(null)

    const toggleExpand = (id) => {
        let temp_rows = [...rows]
        let objIndex = temp_rows.findIndex((obj => obj.id === id));
        temp_rows[objIndex].expanded = !temp_rows[objIndex].expanded
        setDisplayNotes(temp_rows[objIndex].notes)
        setRows(temp_rows)
    }

    // Edit Company Submitters
    const [company_id, setCompany_id] = useState(null);
    const [showEditCompanySubmittersDialog, setShowEditCompanySubmittersDialog] = useState(false);


    return (
        <>
          {loading ? (
            <>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                Loading...
              </div>
            </>
            ) : (
            <div className={classes.content}>
				<div className={classes.topblock}>
					<div>
						<Typography variant="h4">Companies</Typography>
						<Typography variant="h6">All the companies on the project providing closeout documentation</Typography>
					</div>
					<div style={{marginLeft: 'auto'}}>
                            <Button variant="contained" component={Link} to="/project_directory_setup" style={{backgroundColor: COLOR_THEME.classic_blue, color: 'white'}}>
                                Add/Edit Company
                            </Button>
					</div>
				</div>
				<br />
				<br />
				<div>
					<div>
                    <TableContainer component={Paper}>
                        <Table 
                            sx={{ minWidth: 650 }} 
                            stickyHeader aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Trade</TableCell>
                                    <TableCell >Users #</TableCell>
                                    <TableCell >Notes</TableCell>
                                    <TableCell align="right">Users</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {rows.map((row) => (
                                <>
                                <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'border': '1px solid gray' }}
                                onClick={() => toggleExpand(row.id)}
                                >
                                    <TableCell scope="row" style={{cursor: 'pointer'}} >
                                        <Typography
                                            style={{color: 'black',textDecoration: 'none'}}
                                            component={Link}
                                            to={'project_requirements/'+row.id}      
                                        >
                                            {row.name}
                                            &ensp;
                                            <ArrowOutwardIcon fontSize='small' style={{alignSelf: 'center', color: COLOR_THEME.light_blue}} />
                                        </Typography>
                                    </TableCell>
                                    <TableCell >{row.trades}</TableCell>
                                    <TableCell >{row.personnel.length} {((row.personnel.length === 0)||(row.personnel.length > 10)) ? (<WarningAmberIcon style={{color: 'red'}} />) : <PersonIcon />}</TableCell>
                                    <TableCell >
                                        {row.notes ? <NotesIcon style={{color: COLOR_THEME.light_blue}} /> : null}
                                    </TableCell>
                                    
                                    <TableCell align="right" style={{padding: '10px'}}>{row.expanded ? <ExpandLessIcon/> : <ExpandMoreIcon />}</TableCell>
                                </TableRow>
                                {row.expanded && (
                                    <div style={{paddingTop: '10px', paddingBottom: '10px', paddingLeft: '50px'}}>
                                        <div>
                                            <div style={{display: 'flex'}}>
                                                <Typography variant="h5">Notes</Typography>
                                            </div>
                                            <br />
                                            <TextField 
                                                label="Add notes here..."
                                                multiline = {true}
                                                fullWidth
                                                value = {displayNotes}
                                                onChange={(e) => setDisplayNotes(e.target.value)}
                                            >
                                            </TextField>       
                                            <br /><br />
                                            <Button onClick={() => updateNotes(row.id,{"notes": displayNotes})} variant="contained" className={classes.approveButton}>
                                                Save
                                            </Button>
                                        </div>
                                        <br /><br />

                                        <div>
                                            <Typography variant="h5">Submitters</Typography>
                                            <div style={{padding: '10px', float: 'right'}}>
                                                <Button 
                                                    variant="contained" 
                                                    style={{backgroundColor: COLOR_THEME.light_blue, color: 'white'}}
                                                    onClick={() => {
                                                        fetchSubmitterData(row.id)
                                                        setShowEditCompanySubmittersDialog(true)
                                                        setCompany_id(row.id)
                                                    }}
                                                >✏️ Edit</Button>
                                            </div>
                                        <TableRow key={row.id}>
                                                <Table style={{ minWidth: 650, border: '1px solid gray', padding: '5px' }} >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell><PersonIcon /></TableCell>
                                                            <TableCell align="right">Email</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {row.personnel.map((person) => (
                                                            <TableRow key={person.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                <TableCell>
                                                                    {person.first_name} {person.last_name}
                                                                </TableCell>
                                                                <TableCell align="right">{person.email}</TableCell>                                                        
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                        </TableRow>
                                        </div>
                                    </div>
                                )}
                                </>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div>
                        <EditCompanySubmitters 
                            showEditCompanySubmittersDialog={showEditCompanySubmittersDialog}
                            setShowEditCompanySubmittersDialog={setShowEditCompanySubmittersDialog}
                            company_directory={company_directory}
                            procore_users={procore_users}
                            saveSubmittersData={saveSubmittersData}
                            company_id={company_id}
                        />
                    </div>
                    </div>
				</div>
            </div>
          )}
        </>
    );
};


export default ProjectDirectory;