import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper, TextField } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { COLOR_THEME } from '../common/constants'
import Checkbox from "@mui/material/Checkbox";
import Navigation from './navigation';
import PersonIcon from '@mui/icons-material/Person';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';


const steps = [
    "Select Requirements",
    "Send Message"
];

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	type_option: {
		display: 'flex',
        '&:hover': {
            border: '1px solid #093D61',
        }
	},
    nextButton: {
        color: 'white', 
        backgroundColor: COLOR_THEME.classic_blue,        
        "& .Mui-disabled": {
            backgroundColor: 'gray',
            }
    },
}));
 
const RequestDocuments = props => {
    let { showRequestDocumentsDialog, setShowRequestDocumentsDialog, requirement_list,requestDocuments } = props;
    const classes = useStyles();

    const [requestRows, setRequestRows] = useState([])

    // Navigator
    const [activeStep, setActiveStep] = useState(0);
    const ChangeStep = (action) => {
        if (action==='next') {
            setActiveStep(activeStep+1)
            if (activeStep === 1) {
                // Request the documents from Subs               
                requestDocuments(
                    {
                        'requirements': selectedRequirements, 
                        'message': (message === 'Enter additional custom message for the submitters.') ? '---' : message
                    }
                )
                // Resetting state variables
                resetDialog()
            }
        } else if (action==='back') {
            activeStep === 0 ? resetDialog() : setActiveStep(activeStep-1)
        } 
    }

    const resetDialog = () => {
        // Resetting state variables
        setShowRequestDocumentsDialog(false)
        setActiveStep(0)
        setMessage('Enter additional custom message for the submitters.')
    }

    // Step 1
    const [selectedRequirements, setSelectedRequirements] = useState([]);
    const isSelected = (id) => selectedRequirements.indexOf(id) !== -1;

    useEffect(() => {
        // Essentially part of dialog reset. Making sure when you reopen dialog box, it's fresh.
        setRequestRows(requirement_list.filter(e => e.status === 'Draft'))
        setSelectedRequirements(requestRows.map(e => e.id))
    },[requirement_list])


    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelected = requestRows.map((n) => n.id);
          setSelectedRequirements(newSelected);
          return;
        }
        setSelectedRequirements([]);
      };
    
    const handleCheckboxClick = (event, id) => {
        const selectedIndex = selectedRequirements.indexOf(id);
        let newSelected = [];
   
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRequirements, id);
            } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRequirements.slice(1));
            } else if (selectedIndex === selectedRequirements.length - 1) {
            newSelected = newSelected.concat(selectedRequirements.slice(0, -1));
            } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRequirements.slice(0, selectedIndex),
                selectedRequirements.slice(selectedIndex + 1)
            );
            }      
        setSelectedRequirements(newSelected);
      };

    // Step 2:
    const [message, setMessage] = useState('Enter additional custom message for the submitters.')
    const changeText = (e) => setMessage(e.target.value)


    return (
        <div>
            <Dialog
                open={showRequestDocumentsDialog}
                onClose={() => resetDialog()}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Request Documents</DialogTitle>
                <DialogContent>
                        <>
                            <Navigation step={activeStep} steps={steps}/>
                            <br /> <br/>
                            {(activeStep === 0) && (
                                <div>
                                    <Typography variant='h5'>{selectedRequirements.length} of {requirement_list.filter(e => e.status === 'Draft').length} Draft Requirements Selected</Typography> 
                                    <Typography variant='subtitle1'>Select all the requirements to be requested from the associated subcontractors.</Typography> 
                                    <br />
                                    <div>
                                        <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                                            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                color="primary"
                                                                checked={requestRows.length > 0 && selectedRequirements.length === requestRows.length}
                                                                onChange={handleSelectAllClick}
                                                                inputProps={{"aria-label": "select all"}}
                                                            />
                                                        </TableCell>
                                                        <TableCell>Subcontractor</TableCell>
                                                        <TableCell>Type</TableCell>
                                                        <TableCell>Parent Type</TableCell>
                                                        <TableCell>Email Recipients</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {requestRows.map((row, index) => (
                                                            <TableRow
                                                                hover
                                                                onClick={(event) => handleCheckboxClick(event, row.id)}
                                                                tabIndex={-1}
                                                                selected={isSelected(row.id)}
                                                                key={row.id}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell padding="checkbox">
                                                                    <Checkbox
                                                                        color="primary"
                                                                        checked={isSelected(row.id)}
                                                                        inputProps={{
                                                                            "aria-labelledby": `enhanced-table-checkbox-${index}`
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">{row.company_name}</TableCell>
                                                                <TableCell component="th" scope="row">{row.requirement_type}</TableCell>
                                                                <TableCell component="th" scope="row">{row.requirement_parent_type}</TableCell>
                                                                <TableCell component="th" scope="row">{row.company_recipients} {((row.company_recipients === 0)||(row.company_recipients > 10)) ? (<WarningAmberIcon style={{color: 'red'}} />) : <PersonIcon />}</TableCell>
                                                            </TableRow>
                                                        )
                                                )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            )}
                            {(activeStep === 1) && (
                                <div>
                                    <Typography variant='h6'>To</Typography>
                                    <TextField id="outlined-basic" variant="outlined" disabled size="small" style={{minWidth:'100%'}} multiline
                                        defaultValue={[...new Set(requestRows.filter(e => selectedRequirements.includes(e.id)).map(e => e.company_name))].join(', ')}
                                    />
                                    <br /><br />
                                    <Typography variant='h6'>Body</Typography>
                                    <TextField id="outlined-basic" variant="outlined" size="small" style={{minWidth:'100%'}} multiline
                                        value={message} onChange={e => changeText(e)}
                                    />
                                </div>
                            )}
                        </>
                        <br /><br />
                        {activeStep !== 1 && <Typography variant='subtitle2'>Clicking "Next" will take you to the next step, and not send the email to the subs just yet.</Typography>}
                </DialogContent>
                <DialogActions style={{padding: '15px', paddingRight: '25px', paddingBottom: '25px'}}>
                    <Button onClick={() => ChangeStep('back')}>{activeStep === 0 ? 'Cancel' : 'Back'}</Button>
                    <Button onClick={() => ChangeStep('next')} autoFocus variant="contained" className={classes.nextButton}
                        disabled={(selectedRequirements.length>0) ? false:true} 
                    >
                        {activeStep !== 1 ? 'Next' : 'Send Request Emails'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};


export default RequestDocuments;