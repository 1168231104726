export const SFDC_STAGE_OPTIONS = ([
    {'title': 'Work in Progress', 'probability': 100, 'sorting_order': 8}, 
    {'title': 'Completed, Closeout Remaining', 'probability': 100, 'sorting_order': 9}, 
    {'title': 'Completed Jobs', 'probability': 100, 'sorting_order': 10}, 
  ]);


export const COLOR_THEME = ({
    'classic_blue': '#093D61',
    'light_blue': '#00AEEF',
    'selected_blue': '#A5D4E6',
    'procore_orange': '#FF5200',
});

export const PROGRESS_COLORS = ({
    'Draft': 'danger',
    'Requested': 'info',
    'Submitted': 'warning',
    'Approved': 'success',
});