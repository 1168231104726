import React, { Component } from 'react';
import Axios from 'axios';
import { API_URL } from '../../utils/config/config';
import { withAuth0 } from "@auth0/auth0-react";
import DocumentRequest from '../../components/pre-security/document_request/document_request';

import {Typography, Button} from '@material-ui/core';
import { Replay } from '@mui/icons-material';
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@mui/lab/Alert';

import { withStyles } from '@material-ui/core';

const Styles = theme => ({
    root_grid: {
        display: 'grid',
        gridTemplateRows: '65px auto',
        overflowY: 'visible',
    },
    header: {
        zIndex: 999999,
        maxHeight: '65px',
    },
});

class DocumentRequestContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unique_key: null,
            requirement_id: null,
            loading: true,
            data: {},
            snackbar_show: false,
            snackbar_message: '',
            snackbar_type: 'info',
        };
        this.fetchData = this.fetchData.bind()
        this.formSubmitContainer = this.formSubmitContainer.bind()
    }

    fetchData = async () => {
        try {    
            let { unique_key, requirement_id } = this.props.match.params;
            // Tracking
            let res = {data: {}}
            // try {
            //     res = await Axios.get("https://geolocation-db.com/json/");            
            //     if (localStorage.getItem('user_id')) {
            //         res.data['user_id'] = localStorage.getItem('user_id')
            //     }  
            // } catch (error){
            //     console.log('error',error)
            // }

            const response1 = await Axios.post(`${API_URL}fetch_document_request_page_data/${unique_key}/`, 
            res.data,
            {
                headers: {
                    'content-type': 'application/json',
                },
            });

            this.setState({
                loading: false,
                data: response1.data.data,
                requirement_id: requirement_id
            })
        } catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    formSubmitContainer = async (formData, requirement_id) => {
        try {
            this.setState({snackbar_show: true});
            this.setState({snackbar_message: 'Uploading...'});
            this.setState({snackbar_type: 'info'});  

            const saveRequirement = await Axios.patch(`${API_URL}requirements/${requirement_id}/`, 
                formData,
                {
                    headers: {
                        'Content-Type': `multipart/form-data`,
                    },
                }).then(function (response) {
                console.log('here')
        });    
        this.setState({snackbar_show: true});
        this.setState({snackbar_message: 'File has been uploaded successfully.'});
        this.setState({snackbar_type: 'success'});     
        this.fetchData();   

        } catch (error) {
            console.log(error)
            this.setState({ ...this.state, error: error, loading: false });
            this.setState({snackbar_show: true});
            this.setState({snackbar_message: 'File failed to upload.'});
            this.setState({snackbar_type: 'error'});        
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        const { classes } = this.props;        

        return (
            <>
                <div className={classes.root_grid}>
                    <div className={classes.header}>
                        {/* <Header /> */}
                    </div>
                    <div>
                        {this.state.offline ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                You are offline!
                            </div>
                        ) : this.state.error ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                                    <strong>An error occured!</strong>
                                </Typography>
                                <center>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => window.location.reload()}
                                    >
                                        <Replay /> Try again
                                    </Button>
                                </center>
                            </div>
                        ) : (      
                                <div  className={classes.main}>
                                    <Snackbar 
                                        anchorOrigin={{ vertical: 'bottom',horizontal: 'center'}}
                                        open={this.state.snackbar_show}
                                        autoHideDuration={this.state.snackbar_type === 'success' ? 3000 : 120000}
                                        onClose={() => this.setState({snackbar_show: false})}
                                    >
                                        <MuiAlert elevation={6} variant="filled" severity={this.state.snackbar_type}>
                                            {this.state.snackbar_message}
                                        </MuiAlert>
                                    </Snackbar>
                                    <DocumentRequest 
                                        loading={this.state.loading}
                                        data={this.state.data}
                                        url_requirement_id={this.state.requirement_id}
                                        formSubmitContainer={this.formSubmitContainer}
                                    />
                                </div>
                            )}
                        </div>
                    <div>
                    </div>                
                </div>

            </>
        );
    }
}

export default withAuth0(withStyles(Styles)(DocumentRequestContainer));
