import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import _ from 'lodash';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ProgressBar from 'react-bootstrap/ProgressBar';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab';

import { FRONT_END_URL } from '../../../utils/config/config';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import NewRequirement from './new_requirement';
import RequestDocumentsDialog from './request_documents';
import RemindCompaniesDialog from './remind_companies';
import DeleteRequirementsDialog from './delete_requirements';
import ReviewSubmissionDialog from './review_submission';
import EditCompanySubmitters from './edit_company_submitters';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import fetchFile from '../../../utils/fetchFile';

import { COLOR_THEME, PROGRESS_COLORS } from '../common/constants'

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	topblock: {
		display: 'flex'
	},
    approveButton: {
        color: 'white', 
        backgroundColor: '#198754',        
        "& .Mui-disabled": {
            backgroundColor: 'gray',
            }
    },
    rejectButton: {
        color: '#dc3545', 
        border: '#dc3545 1px solid',        
        "& .Mui-disabled": {
            backgroundColor: 'gray',
            }
    },
}));
 
//Function that pulls the data and sends it to the Table Renderer
const Home = props => {
    let {loading, company_id, company_name, company_directory, company_unique_key, requirements, requirements_by_company, requirements_by_requirement_type, progress_data, activity_log, notes, company_list,requirement_types,createRequirements, procore_users, saveSubmittersData, requestDocuments,remindCompanies, deleteRequirements, reviewSubmission, updateNotes } = props;
    const classes = useStyles();
    // Create New Requirement
    const [showDialog, setShowDialog] = useState(false);
    
    // Request Documents
    const [showRequestDocumentsDialog, setShowRequestDocumentsDialog] = useState(false);
    // Remind Documents
    const [showRemindDocumentsDialog, setShowRemindDocumentsDialog] = useState(false);
    // Edit Company Submitters
    const [showEditCompanySubmittersDialog, setShowEditCompanySubmittersDialog] = useState(false);
    // Review Submission
    const [showReviewSubmissionDialog, setShowReviewSubmissionDialog] = useState(false);
    const [reviewSubmissionDetails, setReviewSubmissionDetails] = useState({});
    // Delete Requirements
    const [showDeleteRequirementsDialog, setShowDeleteRequirementsDialog] = useState(false);

    const [tab, setTab] = React.useState(localStorage.getItem('requirements_tab') || 'All');
    const [tabCounts, setTabCounts] = React.useState({'All': 0, 'Draft': 0, 'Requested':0, 'Submitted':0, 'Approved':0})

    const [ogNotes, setOgNotes] = useState(notes)
    const [displayNotes, setDisplayNotes] = useState(ogNotes)

    useEffect(() => {
        setOgNotes(notes)
        setDisplayNotes(notes)
    },[notes])

    // Actions Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        if (event.target.innerText === 'Request Draft Requirements') {
            setShowRequestDocumentsDialog(true)
        } else if (event.target.innerText === 'Send Reminders') {
            setShowRemindDocumentsDialog(true)
        } else if (event.target.innerText === 'Delete Requirements') {
            setShowDeleteRequirementsDialog(true)
        }
    setAnchorEl(null);
    };

    // Variable for rendering rows in the table.
    const [rows, setRows] = useState(requirements);
    let changeTab = (e, value) => {
        setTab(value)
        localStorage.setItem('requirements_tab', value);
    }

    const [view,setView] = useState(parseInt(localStorage.getItem('requirements_view')) || 1);
    const changeView = (event, newAlignment) => {
        setView(parseInt(newAlignment));
        localStorage.setItem('requirements_view', parseInt(newAlignment));
    };

    const toggleExpand = (id) => {
        let temp_rows = [...rows]
        let objIndex = view === 2 ? temp_rows.findIndex((obj => obj.company_name === id)) : temp_rows.findIndex((obj => obj.requirement_type_name === id));
        temp_rows[objIndex].expanded = !temp_rows[objIndex].expanded
        setRows(temp_rows)
    }

    let filterRequirements = () => {
        let rows_temp = requirements

        // Counting TabCounts
        if (rows_temp.length>0) {
            setTabCounts(
                {
                    'All': rows_temp.length, 
                    'Draft': rows_temp.reduce((acc, cur) => cur.status === 'Draft' ? ++acc : acc, 0), 
                    'Requested':rows_temp.reduce((acc, cur) => cur.status === 'Requested' ? ++acc : acc, 0), 
                    'Submitted':rows_temp.reduce((acc, cur) => cur.status === 'Submitted' ? ++acc : acc, 0), 
                    'Approved':rows_temp.reduce((acc, cur) => cur.status === 'Approved' ? ++acc : acc, 0)
                }
            )    

            if (view === 1) {
                // Filtering for the status
                rows_temp = (tab==='All') ? rows_temp : rows_temp.filter(row=> row.status===tab)
            } else {
                rows_temp = _.cloneDeep(view === 2 ? requirements_by_company : requirements_by_requirement_type)

                // Filtering for the status
                if (tab !=='All') {
                    for (let row of rows_temp) {
                        row['requirements_data'] = row['requirements_data'].filter(e => (e.status === tab))
                    }
                    rows_temp = rows_temp.filter(row => (row['requirements_data'].length > 0))
                }
            } 
        }
        setRows(rows_temp)
    }

    useEffect(() => {
        filterRequirements(``)    
    },[requirements, tab, view])

    return (
        <>
          {loading ? (
            <>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                Loading...
              </div>
            </>
            ) : (
            <div className={classes.content}>
				<div className={classes.topblock}>
					<div style={{display: 'flex'}}>
						<Typography variant="h4">Requirements</Typography>
                        {company_id && (
                            <Typography variant="h4" style={{color: 'gray'}}>&nbsp;for {company_name}</Typography>
                        )}
					</div>
					<div style={{marginLeft: 'auto'}}>
                        <Button 
                            variant="contained" 
                            style={{backgroundColor: COLOR_THEME.light_blue, color: 'white'}}
                            onClick={handleClick}
                        >
                            Actions
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem key='request' onClick={handleClose}>Request Draft Requirements</MenuItem>
                            <MenuItem key='remind' onClick={handleClose}>Send Reminders</MenuItem>
                            <MenuItem key='delete' onClick={handleClose}>Delete Requirements</MenuItem>
                        </Menu>                        
                        &emsp;
						<Button 
                            variant="contained" 
                            style={{backgroundColor: COLOR_THEME.classic_blue, color: 'white'}}
                            onClick={()=> setShowDialog(true)}
                        >
                            New Requirement
                        </Button>
					</div>
				</div>
				<br />
				<br />
                {/* DialogBox */}
                <div>
                    <NewRequirement 
                        showDialog={showDialog}
                        setShowDialog={setShowDialog}
                        company_list={company_list}
                        requirement_types={requirement_types}
                        createRequirements={createRequirements}
                        requirements_by_requirement_type={requirements_by_requirement_type}
                    />
                </div>
                <div>
                    <RequestDocumentsDialog
                        showRequestDocumentsDialog={showRequestDocumentsDialog}
                        setShowRequestDocumentsDialog={setShowRequestDocumentsDialog}
                        requirement_list={requirements}
                        requestDocuments={requestDocuments}
                    />
                </div>
                <div>
                    <RemindCompaniesDialog
                        showRemindDocumentsDialog={showRemindDocumentsDialog}
                        setShowRemindDocumentsDialog={setShowRemindDocumentsDialog}
                        requirement_list={requirements_by_company}
                        remindCompanies={remindCompanies}
                    />
                </div>
                <div>
                    <DeleteRequirementsDialog
                        showDeleteRequirementsDialog={showDeleteRequirementsDialog}
                        setShowDeleteRequirementsDialog={setShowDeleteRequirementsDialog}
                        requirement_list={requirements}
                        deleteRequirements={deleteRequirements}
                    />
                </div>

                <div>
                    <ReviewSubmissionDialog
                        showReviewSubmissionDialog={showReviewSubmissionDialog}
                        setShowReviewSubmissionDialog={setShowReviewSubmissionDialog}
                        reviewSubmissionDetails={reviewSubmissionDetails}
                        reviewSubmission={reviewSubmission}
                    />
                </div>
                <div>
                    <EditCompanySubmitters 
                        showEditCompanySubmittersDialog={showEditCompanySubmittersDialog}
                        setShowEditCompanySubmittersDialog={setShowEditCompanySubmittersDialog}
                        company_directory={company_directory}
                        procore_users={procore_users}
                        saveSubmittersData={saveSubmittersData}
                        company_id={company_id}
                    />
                </div>
				<div>
                    {/* ProgressBox */}
                    <div>
                        <Paper elevation={4} style={{padding:'10px'}}>
                        <div>
                            <div style={{display: 'flex'}}>
                                <Typography variant="h5">Total Requirements</Typography>
                                <div style={{marginLeft: 'auto'}}>
                                    <FiberManualRecordIcon style={{color: '#dc3545'}}/>
                                    Draft ({progress_data.find(obj => obj.status==='Draft')?.total})&ensp;
                                    <FiberManualRecordIcon style={{color: '#0dcaf0'}}/>
                                    Requested ({progress_data.find(obj => obj.status==='Requested')?.total})&ensp;
                                    <FiberManualRecordIcon style={{color: '#ffc107'}}/>
                                    Submitted ({progress_data.find(obj => obj.status==='Submitted')?.total})&ensp;
                                    <FiberManualRecordIcon style={{color: '#198754'}}/>
                                    Approved ({progress_data.find(obj => obj.status==='Approved')?.total})
                                </div>
                            </div>
                            <Typography variant="h4">{requirements.length}</Typography>
                        </div>
                        <br />
                        <ProgressBar>
                            {progress_data.map(status => (
                                <ProgressBar 
                                    key={status.status}
                                    now={status.percent} 
                                    variant={PROGRESS_COLORS[status.status]}
                                />
                            ))}
                        </ProgressBar>
                        <div style={{display: 'flex'}}>
                            0%
                            <div style={{marginLeft: 'auto'}}>100%</div>
                        </div>
                        </Paper>
                    </div>
                    <br />
                    <br />
                    {/* RequirementsBox */}
					<div>
                        <Paper elevation={4}>
                            <div style={{display: 'flex', padding: '5px'}}>
                                <ToggleButtonGroup value={view.toString()} style={{marginLeft: 'auto'}} onChange={changeView} exclusive>
                                    <ToggleButton value='1' style={{color: 'black'}}>
                                        List View
                                    </ToggleButton>
                                    <ToggleButton value='2' style={{color: 'black'}}>
                                        Group by Company
                                    </ToggleButton>
                                    <ToggleButton value='3' style={{color: 'black'}}>
                                        Group by Type
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                            <br />
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }} style={{display:'flex'}}>
                                <Tabs value={tab} onChange={(e, newValue) => changeTab(e, newValue)}>
                                    {Object.entries(tabCounts).map((key) => (
                                        <Tab key={key} label={key[0]+" ("+key[1]+")"} value={key[0]} style={{width:'160px', borderRight: '0.1px gray solid', borderTop: '0.1px gray solid', color: 'black'}} />
                                    ))}
                                </Tabs>
                                <div style={{marginLeft:'auto'}}>
                                    {(tab==='Draft') && (
                                        <>
                                            <Button 
                                                variant="contained" 
                                                style={{backgroundColor: COLOR_THEME.classic_blue, color: 'white'}}
                                                onClick={()=> setShowRequestDocumentsDialog(true)}
                                            >
                                                Request Documents
                                            </Button>
                                        </>
                                    )}
                                    {(tab==='Requested') && (
                                        <>
                                            <Button 
                                                variant="contained" 
                                                style={{backgroundColor: COLOR_THEME.classic_blue, color: 'white'}}
                                                onClick={()=> setShowRemindDocumentsDialog(true)}
                                            >
                                                Send Reminder
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </Box>
                            <br />
                            <TableContainer component={Paper}>
                                    {view === 1 && (
                                        <Table sx={{ minWidth: '85vw' }} stickyHeader aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Subcontractor</TableCell>
                                                    <TableCell align="right">Title</TableCell>
                                                    <TableCell align="right">Type</TableCell>
                                                    <TableCell align="right">Status</TableCell>
                                                    <TableCell align="right">Date Uploaded</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row" style={{display:'flex', cursor: 'pointer'}} 
                                                            onClick={() => window.open(FRONT_END_URL+'/project_requirements/'+row.company_id.toString(), '_self')}
                                                        >
                                                            {row.company_name}
                                                            &ensp;
                                                            <ArrowOutwardIcon fontSize='small' style={{alignSelf: 'center', color: COLOR_THEME.light_blue}} />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {row.title}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {row.requirement_type}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {(row.status==='Submitted') ? (
                                                                <> 
                                                                    <Chip 
                                                                        label="Review Submission" 
                                                                        color="primary" size="small"
                                                                        onClick={async () => {
                                                                            let [file_path, file_type] = await fetchFile('requirement_file',row.id)
                                                                            setShowReviewSubmissionDialog(true)
                                                                            setReviewSubmissionDetails({
                                                                                id: row.id,
                                                                                title: row.title,
                                                                                company_name: row.company_name,
                                                                                company_unique_key: company_list.filter(e => e.id === row.company_id)[0].unique_key,
                                                                                file_path: file_path,
                                                                                file_type: file_type,
                                                                                status: row.status,
                                                                                multiple_files: row.multiple_files,
                                                            
                                                                            })
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : (row.status==='Approved') ? (
                                                                <>
                                                                    <Chip 
                                                                        label="View Document" 
                                                                        style={{backgroundColor: 'green', color: 'white'}}
                                                                        size="small"
                                                                        onClick={async () => {
                                                                            let [file_path, file_type] = await fetchFile('requirement_file',row.id)
                                                                            setShowReviewSubmissionDialog(true)
                                                                            setReviewSubmissionDetails({
                                                                                id: row.id,
                                                                                title: row.title,
                                                                                company_name: row.company_name,
                                                                                file_path: file_path,
                                                                                file_type: file_type,
                                                                                status: row.status
                                                                            })
                                                                        }}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {row.status}
                                                                </>)}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {((row.status==='Submitted') && row.date_updated)  ? moment(row.date_updated).format('MMM-DD-YYYY') : ''}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>                                        
                                    )}
                                    {view === 2 && (
                                        <Table sx={{ minWidth: '85vw' }} stickyHeader aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Subcontractor</TableCell>
                                                    <TableCell>#</TableCell>
                                                    <TableCell align="right">Requirements</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows && rows.map((row) => (
                                                    (row.hasOwnProperty('expanded') && (
                                                    <React.Fragment key={row.company_id+1000}>
                                                    <TableRow key={row.company_name+'__'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row" style={{display:'flex', cursor: 'pointer'}}>
                                                            {row.company_name}&ensp;
                                                            <ArrowOutwardIcon fontSize='small' style={{alignSelf: 'center', color: COLOR_THEME.light_blue}} 
                                                                onClick={() => window.open(FRONT_END_URL+'/project_requirements/'+row.company_id.toString(), '_self')}                                                            
                                                            />
                                                        </TableCell>
                                                        <TableCell>{row.requirements_data ? row.requirements_data.length : ''}</TableCell>
                                                        <TableCell align="right" style={{padding: '10px'}} onClick={() => toggleExpand(row.company_name)}
                                                        >{row.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</TableCell>
                                                    </TableRow>
                                                    {row.expanded && (
                                                        <div style={{paddingLeft: '50px'}}>
                                                            <TableRow key={row.company_name+'___'}>
                                                                <Table style={{ minWidth: '70vw'}} >
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>Title</TableCell>
                                                                            <TableCell align="right">Type</TableCell>
                                                                            <TableCell align="right">Status</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {row.requirements_data.map((requirement) => (
                                                                            <TableRow key={requirement.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                                <TableCell>
                                                                                    {requirement.title}
                                                                                </TableCell>
                                                                                <TableCell align="right">
                                                                                    {requirement.requirement_type}
                                                                                </TableCell>
                                                                                <TableCell align="right">
                                                                                    {(requirement.status==='Submitted') ? (
                                                                                        <>
                                                                                            <Chip 
                                                                                                label="Review Submission" 
                                                                                                color="primary" size="small"
                                                                                                onClick={async () => {
                                                                                                    let [file_path, file_type] = await fetchFile('requirement_file',requirement.id)

                                                                                                    setShowReviewSubmissionDialog(true)                        
                                                                                                    setReviewSubmissionDetails({
                                                                                                        id: requirement.id,
                                                                                                        title: requirement.title,
                                                                                                        company_name: requirement.company_name,
                                                                                                        file_path: file_path,
                                                                                                        file_type: file_type,
                                                                                                        status: requirement.status
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                        </>
                                                                                    ) : (requirement.status==='Approved') ? (
                                                                                        <>
                                                                                            <Chip 
                                                                                                label="View Document" 
                                                                                                style={{backgroundColor: 'green', color: 'white'}}
                                                                                                size="small"
                                                                                                onClick={async () => {
                                                                                                    let [file_path, file_type] = await fetchFile('requirement_file',requirement.id)

                                                                                                    setShowReviewSubmissionDialog(true)                        
                                                                                                    setReviewSubmissionDetails({
                                                                                                        id: requirement.id,
                                                                                                        title: requirement.title,
                                                                                                        company_name: requirement.company_name,
                                                                                                        file_path: file_path,
                                                                                                        file_type: file_type,
                                                                                                        status: requirement.status
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                        </>
                                                                                    ) : requirement.status}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableRow>
                                                        </div>
                                                    )}
                                                    </React.Fragment>
                                                    ))
                                                ))}
                                            </TableBody>
                                        </Table>                                        
                                    )}
                                    {view === 3 && (
                                        <Table sx={{ minWidth: '85vw' }} stickyHeader aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Requirement Type</TableCell>
                                                    <TableCell>#</TableCell>
                                                    <TableCell align="right">Requirements</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row) => (
                                                    (row.hasOwnProperty('requirement_type_name') && (
                                                        <React.Fragment key={row.requirement_type_name}>
                                                            <TableRow
                                                                key={row.requirement_type_name}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                onClick={() => toggleExpand(row.requirement_type_name)}
                                                            >
                                                                <TableCell component="th" scope="row" style={{display:'flex'}} >
                                                                    {row.requirement_type_name}
                                                                </TableCell>
                                                                <TableCell>{row.requirements_data ? row.requirements_data.length : ''}</TableCell>
                                                                <TableCell align="right" style={{padding: '10px'}}>{row.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</TableCell>
                                                            </TableRow>
                                                            {row.expanded && (
                                                                <div style={{paddingLeft: '50px'}}>
                                                                    <TableRow key={row.company_name+'_'}>
                                                                        <Table style={{ minWidth: '70vw'}} >
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell>Subcontractor</TableCell>
                                                                                    <TableCell align="right">Title</TableCell>
                                                                                    <TableCell align="right">Type</TableCell>
                                                                                    <TableCell align="right">Status</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {row.requirements_data.map((requirement) => (
                                                                                    <TableRow key={requirement.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                                                        <TableCell>
                                                                                            {requirement.company_name}
                                                                                        </TableCell>
                                                                                        <TableCell align="right">
                                                                                            {requirement.title}
                                                                                        </TableCell>
                                                                                        <TableCell align="right">
                                                                                            {requirement.requirement_type}
                                                                                        </TableCell>
                                                                                        <TableCell align="right">
                                                                                            {(requirement.status==='Submitted') ? (
                                                                                                <>
                                                                                                    <Chip 
                                                                                                        label="Review Submission" 
                                                                                                        color="primary" size="small"
                                                                                                        onClick={async() => {
                                                                                                            let [file_path, file_type] = await fetchFile('requirement_file',requirement.id)

                                                                                                            setShowReviewSubmissionDialog(true)                        
                                                                                                            setReviewSubmissionDetails({
                                                                                                                id: requirement.id,
                                                                                                                title: requirement.title,
                                                                                                                company_name: requirement.company_name,
                                                                                                                file_path: file_path,
                                                                                                                file_type: file_type,
                                                                                                                status: requirement.status
                                                                                                            })
                                                                                                        }}
                                                                                                    />
                                                                                                </>
                                                                                            ) : (requirement.status==='Approved') ? (
                                                                                                <>
                                                                                                    <Chip 
                                                                                                        label="View Document" 
                                                                                                        style={{backgroundColor: 'green', color: 'white'}}
                                                                                                        size="small"
                                                                                                        onClick={async() => {
                                                                                                            let [file_path, file_type] = await fetchFile('requirement_file',requirement.id)

                                                                                                            setShowReviewSubmissionDialog(true)                        
                                                                                                            setReviewSubmissionDetails({
                                                                                                                id: requirement.id,
                                                                                                                title: requirement.title,
                                                                                                                company_name: requirement.company_name,
                                                                                                                file_path: file_path,
                                                                                                                file_type: file_type,
                                                                                                                status: requirement.status
                                                                                                            })
                                                                                                        }}
                                                                                                    />
                                                                                                </>
                                                                                            ) : requirement.status}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableRow>
                                                                </div>
                                                            )}
                                                        </React.Fragment>
                                                    ))
                                                ))}
                                            </TableBody>
                                        </Table>                                        
                                    )}
                            </TableContainer>
                        </Paper>
                    </div>
                    <br />
                    <br />
                    {/* SubmittersBox */}
					{company_id && (
                        <div>
                            <div style={{display: 'flex'}}>
                                <Typography variant="h5">Submitters</Typography>
                                <div style={{marginLeft: 'auto', display: 'inline-flex'}}>
                                    <Typography variant="subtitle1"
                                        component={Link}
                                        to={{ pathname:FRONT_END_URL+'/portal/document_requests/'+company_unique_key}}      
                                        target="_blank" rel="noopener noreferrer"      
                                    >
                                        View Submission Portal
                                    </Typography>  
                                    &emsp;
                                    <Typography 
                                        variant="subtitle1" 
                                        style={{cursor: 'pointer'}}
                                        onClick={() => setShowEditCompanySubmittersDialog(true)}>
                                            ✏️ Edit
                                    </Typography>  
                                    &emsp;
                                    <Button 
                                        variant="contained" 
                                        style={{backgroundColor: COLOR_THEME.classic_blue, color: 'white'}}
                                        onClick={()=> setShowRemindDocumentsDialog(true)}
                                    >
                                        Send Reminder
                                    </Button>

                                </div>                                
                            </div>
                            <br />
                            <TableContainer component={Paper}>
                                <Table 
                                    sx={{ minWidth: 650 }} 
                                    stickyHeader aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell align="right">Email</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {company_directory.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" style={{display:'flex', cursor: 'pointer'}}>
                                                {row.first_name} {row.last_name}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.email}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )}
                    <br />
                    <br />
                    {/* NotesBox */}
                    {company_id && (
                        <div>
                            <div style={{display: 'flex'}}>
                                <Typography variant="h5">Notes</Typography>
                            </div>
                            <br />
                            <TextField 
                                label="Add notes here..."
                                multiline = {true}
                                fullWidth
                                value = {displayNotes}
                                onChange = {(e) => setDisplayNotes(e.target.value)}
                            >
                            </TextField>       
                            <br />
                            <br />
                            <Button onClick={() => updateNotes(company_id,{"notes": displayNotes})} variant="contained" className={classes.approveButton}>
                                Save
                            </Button>
                            &emsp;
                            <Button onClick={() => setDisplayNotes(ogNotes)} variant="contained" className={classes.rejectButton}>
                                Cancel
                            </Button>
                        </div>
                    )}
                    <br />
                    <br />
                    {/* ActivityLogBox */}
					{company_id && activity_log && (
                        <div>
                            <div style={{display: 'flex'}}>
                                <Typography variant="h5">Activity Log</Typography>
                            </div>
                            <br />
                            <Timeline
                                sx={{
                                }}                            
                            >
                                {Object.entries(activity_log).map(([key, value],i) => (
                                    <TimelineItem key={key} style={{minHeight: '50px'}}>
                                        <TimelineOppositeContent color="textSecondary" style={{maxWidth: '150px', paddingTop: '0px'}}>
                                            <Typography variant='subtitle1'>
                                                {moment(key).format('MMM-DD-YYYY')}
                                            </Typography>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot style={{backgroundColor: COLOR_THEME.classic_blue}}/>
                                            {i !== Object.keys(activity_log).length-1 && (
                                                <TimelineConnector style={{backgroundColor: COLOR_THEME.classic_blue}}/>
                                            ) }
                                        </TimelineSeparator>
                                        <TimelineContent style={{paddingTop: '0px'}}>
                                            <Typography variant='subtitle1'>
                                                {value}
                                            </Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}                            

                            </Timeline>
                        </div>
                    )}
				</div>
            </div>
          )}
        </>
    );
};


export default Home;