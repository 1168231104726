import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper, TextField } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import { COLOR_THEME } from '../common/constants'
import Checkbox from "@mui/material/Checkbox";
import {useDropzone} from 'react-dropzone';
import ImageIcon from '@mui/icons-material/Image';

import Autocomplete from '@mui/material/Autocomplete';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import DescriptionIcon from '@mui/icons-material/Description';
import { active } from 'd3';

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	type_option: {
		display: 'flex',
        '&:hover': {
            border: '1px solid #093D61',
        }
	},
    nextButton: {
        color: 'white', 
        backgroundColor: COLOR_THEME.classic_blue,        
        "& .Mui-disabled": {
            backgroundColor: 'gray',
            }
    },
    dropzone: {
        textAlign: "center",
        padding: "10px",
        border: "1px #bdbdbd solid",
        backgroundColor: "#fafafa",
        color: "#717171",
        [theme.breakpoints.up('sm')]: {
            padding: "20px",
        },
    }
}));
 
//Function that pulls the data and sends it to the Table Renderer
const EditRequirement = props => {
    let { showEditDialog, setShowEditDialog, updateRequirementType, currentInformation,setCurrentInformation } = props;
    const classes = useStyles();

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({ 
        maxFiles: 1});

    const resetDialog = () => {
        // Resetting state variables
        setShowEditDialog(false)
    }

    // Navigator
    const ChangeStep = (action) => {
        if (action==='next') {
            // Create the requirements                
            var requirementFormData = new FormData();
            requirementFormData.append('parent_type', currentInformation.type);
            requirementFormData.append('name', currentInformation.category);
            requirementFormData.append('description', currentInformation.description ? currentInformation.description : '');
            requirementFormData.append('project_id', localStorage.getItem('active_project'));
            if (acceptedFiles.length>0) {
                requirementFormData.append('sample_file', acceptedFiles[0]);
            }
            updateRequirementType(requirementFormData,currentInformation.id);
            // Resetting state variables
            resetDialog()
        } else if (action==='back') {
            resetDialog()
        } 
    }

    return (
        <div>
            <Dialog
                open={showEditDialog}
                onClose={() => resetDialog()}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>New Requirement</DialogTitle>
                <DialogContent>
                    <br /> 
                        <div>
                        <Typography variant='h5'>Enter the Requirement details</Typography> 
                        <br />
                        <br />
                        <Typography variant='h6'>Parent Type</Typography> 
                        <TextField style={{minWidth: '25vw'}} id="standard-basic" variant="outlined" 
                            value={currentInformation.type}
                            disabled
                        />
                        <br />
                        <br />
                        <Typography variant='h6'>Type</Typography> 
                        <TextField style={{minWidth: '25vw'}} id="standard-basic" variant="outlined" 
                            value={currentInformation.category}
                            onChange={e => setCurrentInformation({...currentInformation, 'category': e.target.value})}
                            placeholder={'Type'}
                        />
                        <br />
                        <br />
                        <Typography variant='h6'>Description (Optional)</Typography> 
                        <TextField style={{minWidth: '25vw'}} id="standard-basic" variant="outlined" 
                            value={currentInformation.description} multiline rows={3}
                            onChange={e => setCurrentInformation({...currentInformation, 'description': e.target.value})}
                            placeholder={'Description'}
                        />
                        <br />
                        <br />
                        <Typography variant='h6'>Sample File (Optional)</Typography> 
                        <section>
                            <div {...getRootProps({className: classes.dropzone})}>
                                <input {...getInputProps()} />
                                <Typography variant='subtitle1'>Drag 'n' drop or click to select an image file</Typography>
                            </div>
                            <br />
                            {acceptedFiles.length > 0 && (
                                <div style={{display: 'flex', alignItems: 'center', flexWrap:'wrap'}}>
                                    <Typography variant='subtitle1'>New Selected File:</Typography>&nbsp;
                                    <Chip 
                                        icon={<ImageIcon />} 
                                        label={acceptedFiles[0].path.substring(0,25)} 
                                        color='primary'
                                        size='small'
                                    />
                                </div>
                            )}
                        </section>
                        <Typography variant='subtitle2'>Note: This sample file will be accessible to any person with the link to upload files. We recommend that you don't add any confidential/sensitive information in the sample file.</Typography> 
                        <br />
                        <br />
                        </div>
                    <br />
                </DialogContent>
                <DialogActions style={{padding: '15px', paddingRight: '25px', paddingBottom: '25px'}}>
                    <Button onClick={() => ChangeStep('back')}>Cancel</Button>
                    <Button onClick={() => ChangeStep('next')} autoFocus variant="contained" className={classes.nextButton}
                        disabled={(currentInformation.category !== '') ? false:true} 
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};


export default EditRequirement;