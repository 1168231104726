import React, { Component } from 'react';
import Axios from 'axios';
import _, { filter } from 'lodash';

import { API_URL } from '../../utils/config/config';
import { withAuth0 } from "@auth0/auth0-react";

import Header from '../../components/post-security/common/header';
import Sidebar from '../../components/post-security/common/sidebar';
import ProcoreSyncs from '../../components/post-security/procore_syncs/procore_syncs';

import {Typography, Button, Paper, Dialog, withWidth} from '@material-ui/core';
import { Replay } from '@mui/icons-material';
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@mui/lab/Alert';

import { withStyles } from '@material-ui/core';
import { Redirect, withRouter } from 'react-router-dom';

const Styles = theme => ({
    root_grid: {
        display: 'grid',
        gridTemplateRows: '65px auto',
        overflowY: 'visible',
    },
    header: {
        zIndex: 999999,
        maxHeight: '65px',
    },
    inner_grid: {
        display: 'grid',
        gridTemplateColumns: '155px auto',
        overflowY: 'visible',
    },
});

class ProcoreSyncsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            offline: false,
            procore_sync_data: {},
            procore_sync_submittal_data: [],
            existing_submittal_selection: [],
            procore_sync_rfi_data: [],
            existing_rfi_selection: [],
            snackbar_show: false,
            snackbar_message: '',
            snackbar_type: 'info',
        };
        this.updateData = this.updateData.bind()
        this.fetchSubmittalsData = this.fetchSubmittalsData.bind()
        this.saveSubmittalsSelection = this.saveSubmittalsSelection.bind()
        this.fetchRFIData = this.fetchRFIData.bind()
        this.saveRFISelection = this.saveRFISelection.bind()
        
    }

    fetchData = async () => {
        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }

        try {
            const response = await Axios.get(`${API_URL}procore_sync/${localStorage.getItem('user_id')}/`,{
                headers: api_header,
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });
            this.setState({
                ...this.state,
                loading: false,
                procore_sync_data: response.data.data,
            })
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    fetchSubmittalsData = async () => {
        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
        this.setState({
            ...this.state,
            loading: true,
        })

        try {
            const response = await Axios.get(`${API_URL}procore_sync_submittals/${localStorage.getItem('user_id')}/`,{
                headers: api_header,
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });
            this.setState({
                ...this.state,
                loading: false,
                procore_sync_submittal_data: response.data.data,
                existing_submittal_selection: response.data.existing_submittal_selection,
            })
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    fetchRFIData = async () => {
        this.setState({
            ...this.state,
            loading: true,
        })

        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }

        try {
            const response = await Axios.get(`${API_URL}procore_sync_rfis/${localStorage.getItem('user_id')}/`,{
                headers: api_header,
            })
            .catch(error => {
                console.log("fetchRFIData call failed")
                console.log(error)
                this.props.history.push('/');
            });
            this.setState({
                ...this.state,
                loading: false,
                procore_sync_rfi_data: response.data.data,
                existing_rfi_selection: response.data.existing_rfi_selection,
            })
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }


    updateData = async (body) => {
        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }

        try {
            const response = await Axios.patch(`${API_URL}procore_sync/${localStorage.getItem('user_id')}/`,body,{
                headers: api_header,
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });

            this.setState({
                ...this.state,
                loading: false,
                procore_sync_data: response.data.data,
            })
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }
    
    saveSubmittalsSelection = async (body) => {
        this.setState({snackbar_show: true});
        this.setState({snackbar_message: 'Saving...'});
        this.setState({snackbar_type: 'info'});  


        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }

        try {
            const response = await Axios.patch(`${API_URL}save_procore_sync_submittals/${localStorage.getItem('user_id')}/`,body,{
                headers: api_header,
            });

            if (response.status===200) {
                this.setState({
                    ...this.state,
                    loading: false,
                    snackbar_show: true,
                    snackbar_message: 'Selection successfully saved.',
                    snackbar_type: 'success'            
                })
            }
        } catch (error) {
            console.log(error);
            this.setState({ 
                ...this.state, 
                error: error, 
                loading: false, 
                snackbar_show: true,
                snackbar_message: 'Failed to save the selection.',
                snackbar_type: 'error'            
        });
        }
    }

    saveRFISelection = async (body) => {
        this.setState({snackbar_show: true});
        this.setState({snackbar_message: 'Saving...'});
        this.setState({snackbar_type: 'info'});  


        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }

        try {
            const response = await Axios.patch(`${API_URL}save_procore_sync_rfis/${localStorage.getItem('user_id')}/`,body,{
                headers: api_header,
            });

            if (response.status===200) {
                this.setState({
                    ...this.state,
                    loading: false,
                    snackbar_show: true,
                    snackbar_message: 'Selection successfully saved.',
                    snackbar_type: 'success'            
                })
            }
        } catch (error) {
            console.log(error);
            this.setState({ 
                ...this.state, 
                error: error, 
                loading: false, 
                snackbar_show: true,
                snackbar_message: 'Failed to save the selection.',
                snackbar_type: 'error'            
        });
        }
    }


    componentDidMount() {
        this.fetchData();
    }

    render() {  

        const { classes } = this.props;        
        return (
            <>
                <div className={classes.root_grid}>
                    <div className={classes.header}>
                        <Header />
                    </div>
                    <div>
                        {this.state.offline ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                You are offline!
                            </div>
                        ) : this.state.error ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                                    <strong>An error occured!</strong>
                                </Typography>
                                <center>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => window.location.reload()}
                                    >
                                        <Replay /> Try again
                                    </Button>
                                </center>
                            </div>
                        ) : (      
                                <div className={classes.inner_grid}>
                                    <Snackbar 
                                        anchorOrigin={{ vertical: 'bottom',horizontal: 'center'}}
                                        open={this.state.snackbar_show}
                                        autoHideDuration={3000}
                                        onClose={() => this.setState({snackbar_show: false})}
                                    >
                                        <MuiAlert elevation={6} variant="filled" severity={this.state.snackbar_type}>
                                            {this.state.snackbar_message}
                                        </MuiAlert>
                                    </Snackbar>

                                    <div><Sidebar /></div>
                                    <div  className={classes.main}>
                                        <ProcoreSyncs
                                            loading={this.state.loading}
                                            procore_sync_data={this.state.procore_sync_data}
                                            updateData={this.updateData}
                                            fetchSubmittalsData={this.fetchSubmittalsData}
                                            procore_sync_submittal_data={this.state.procore_sync_submittal_data}
                                            existing_submittal_selection={this.state.existing_submittal_selection}
                                            saveSubmittalsSelection={this.saveSubmittalsSelection}
                                            fetchRFIData={this.fetchRFIData}
                                            procore_sync_rfi_data={this.state.procore_sync_rfi_data}
                                            existing_rfi_selection={this.state.existing_rfi_selection}
                                            saveRFISelection={this.saveRFISelection}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    <div>
                    </div>                
                </div>

            </>
        );
    }
}

export default withAuth0(withStyles(Styles)(ProcoreSyncsContainer));
