// Fetches a file from AWS S3.
import Axios from 'axios';
import { API_URL } from './config/config';

const fetchFile = async (file_type,id) => {
    if (id) {
        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
        const response = await Axios.get(`${API_URL}file-view/${localStorage.getItem('user_id')}/${file_type}/${id}/`, {
            headers: api_header,
        });
    
        return [response.data.url, response.data.file_type]
    }
    return null;
};

export default fetchFile;
