import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { Link } from "react-router-dom";
import { COLOR_THEME } from '../post-security/common/constants'
import homepage_graphic from "./homepage_graphic.png";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        minHeight: '100vh',
        // background: 'linear-gradient(90deg, #1CB5E0 0%, #000851 100%), #F18D2B',
        background: '#ffffff',
        mixBlendMode: 'normal',
        position: 'relative',
        height: '100%',
    },
    textWrapper: {
        position: 'absolute',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
        width: '100%',
        color: 'black',
        textAlign: 'center',
    },
    text2: {
        fontFamily: 'Roboto',
        fontWeight: 100,
        fontSize: '25px',
        textAlign: 'center',
        // paddingBottom: '3px',
    },
    text3: {
        fontFamily: 'Roboto',
        fontWeight: 100,
        fontSize: '20px',
        textAlign: 'left',
        // textAlign: 'center',
        // paddingBottom: '3px',
    },
}));

const LandingPageContent = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div>
                <div className={classes.textWrapper}>
                    <br />
                    <br />
                    <Typography variant='h6' className={classes.text2}>
                    Wrap Up is intuitive and simple. 
                    </Typography>
                    <Typography variant='h6' className={classes.text2}>
                    Improve your closeout process drastically
                    by cutting down on tedious workflows and create high quality turnover package.
                    </Typography>
                    <br />
                    <br />
                    <div style={{display: 'flex', paddingLeft: '50px', paddingRight: '50px'}}>
                        <div style={{width: '50%'}}>
                            <br />
                            <Typography variant='h6' className={classes.text3}>
                                With our tight integration with Procore, we enable you to leverage the information already existing in Procore to jumpstart the process.
                            </Typography>
                            <br />
                            <br />
                            <Typography variant='h6' className={classes.text3}>
                                We enable you to include files on Procore. No more downloading of hundreds of Submittals attachments!
                            </Typography>
                            <br />
                            <br />
                            <Typography variant='h6' className={classes.text3}>
                                With one click, create an elegant closeout package that includes a customized cover sheet, table of contents and all your files neatly organised.   
                            </Typography>
                            <br />
                            <Button 
                                component={Link}
                                to='/contactus'
                                variant="contained" style={{color: 'white', backgroundColor: COLOR_THEME.procore_orange}}
                            >
                                See a Demo!
                            </Button>
                        </div>
                        <div style={{width: '50%'}}>
                            <img src={homepage_graphic} style={{width: '90%', border: '1px gray solid'}}/>                            
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default LandingPageContent;
