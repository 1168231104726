import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

const PublicRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticated } = useAuth0();

    if (rest['path'].includes('vis2d')) {
        return (<Route {...rest} render={props => <Component {...props} />} />)
    } else {
        if (isAuthenticated) {
            if (window.location.href.includes('lessons')) {
                return (<Redirect to="/ll/view" />)
            } else {
                // Wrap Up Route
                return (<Redirect to="/project-view" />)
            }
        } else {
            return (<Route {...rest} render={props => <Component {...props} />} />)
        }        
    };
}
export default PublicRoute;