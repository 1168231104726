import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper, TextField } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import { COLOR_THEME } from '../common/constants'
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';



import Navigation from './navigation';
import Autocomplete from '@mui/material/Autocomplete';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import DescriptionIcon from '@mui/icons-material/Description';
import { active } from 'd3';

const steps = [
    "Type",
    "Details",
    "Companies"
];

const options = [
    {
        header: 'Drawings',
        sub: 'As-Builts, Shop Drawings etc.',
        option: 'Drawings',
        icon: <ArchitectureIcon />
    },
    {
        header: 'Maintenance',
        sub: 'O&M Manuals, Preventative Maintenance, Training Videos etc.',
        option: 'Maintenance',
        icon: <HomeRepairServiceIcon />
    },
    {
        header: 'Warranty',
        sub: 'Warranty Documents, Workmanship Warranty etc.',
        option: 'Warranty',
        icon: <WorkspacePremiumIcon />
    },
    {
        header: 'Other Document',
        sub: 'Attic Stock, Installation Certificates etc.',
        option: 'Other',
        icon: <DescriptionIcon />
    },
]

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	type_option: {
		display: 'flex',
        '&:hover': {
            border: '1px solid #093D61',
        }
	},
    nextButton: {
        color: 'white', 
        backgroundColor: COLOR_THEME.classic_blue,        
        "& .Mui-disabled": {
            backgroundColor: 'gray',
            }
    },
}));
 
//Function that pulls the data and sends it to the Table Renderer
const NewRequirement = props => {
    let { showDialog, setShowDialog, company_list, requirement_types, createRequirements, requirements_by_requirement_type } = props;
    const classes = useStyles();

    // Step 1
    const [type, setType] = useState(null);

    // Step 2
    const [category, setCategory] = useState({id: null, name: 'Please select an option', type:type});
    const [title, setTitle] = useState('');
    const [multiRequirement, setMultiRequirement] = useState(false);

    // Step 3
    const [selectedCompanies, setSelectedCompanies] = useState([]);

    // Local State Variable
    const [localCompanyList, setLocalCompanyList] = useState(company_list)
    useEffect(() => {
        setLocalCompanyList(company_list)
    },[company_list])

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelected = localCompanyList.map((n) => n.id);
          setSelectedCompanies(newSelected);
          return;
        }
        setSelectedCompanies([]);
      };

    
    const handleCheckboxClick = (event, id) => {
        const selectedIndex = selectedCompanies.indexOf(id); // Finding if the item click on is in selectedCompanies list or not. If it is, output >= 0, else -1.
        let newSelected = [];

        if (activeStep !== 2) {
            newSelected = [id]
        } else {
            if (!multiRequirement) {
                if (selectedIndex === -1) {
                    newSelected = newSelected.concat(selectedCompanies, id);
                } else if (selectedIndex === 0) {
                    newSelected = newSelected.concat(selectedCompanies.slice(1));
                } else if (selectedIndex === selectedCompanies.length - 1) {
                    newSelected = newSelected.concat(selectedCompanies.slice(0, -1));
                } else if (selectedIndex > 0) {
                    newSelected = newSelected.concat(
                        selectedCompanies.slice(0, selectedIndex),
                        selectedCompanies.slice(selectedIndex + 1)
                    );
            } 
        } else {
                if (selectedIndex === -1) {
                    newSelected = [id];
                } else {
                    newSelected = []
                }
            }
        }
        setSelectedCompanies(newSelected);
      };
    
    const isSelected = (id) => selectedCompanies.indexOf(id) !== -1;

    useEffect(() => {
        if (title.includes(',')) {
            setMultiRequirement(true)
        } else {
            setMultiRequirement(false)
        }
    },[title])

    const resetDialog = () => {
        // Resetting state variables
        setShowDialog(false)
        setActiveStep(0)
        setType(null)
        setCategory({id: null, name: 'Please select an option', type:type})
        setTitle('')
        setSelectedCompanies([])
        setMultiRequirement(false)
    }

    // Navigator
    const [activeStep, setActiveStep] = useState(0);
    const ChangeStep = (action) => {
        if (action==='next') {
            setActiveStep(activeStep+1)
            if (activeStep === 1) {
                // filering out the companies that already have same requirement existing to avoid duplicates
                let existing_data = requirements_by_requirement_type.filter(e => e.requirement_type_name === category.name)
                if (existing_data.length > 0) {
                    existing_data = existing_data[0].requirements_data.filter(e => e.title === title).map(e => e.company_id)
                }
                setLocalCompanyList(localCompanyList.filter(e => !existing_data.includes(e.id)))
            } else if (activeStep === 2) {
                // Create the requirements                
                createRequirements(
                    {
                        'type': category.id, // ID
                        'title': title,
                        'companies': selectedCompanies
                    }
                )
                // Resetting state variables
                resetDialog()
            }
        } else if (action==='back') {
            setLocalCompanyList(company_list)
            if (activeStep === 1) {
                setCategory({id: null, name: 'Please select an option', type:type})
                setTitle('')
            }
    
            activeStep === 0 ? resetDialog() : setActiveStep(activeStep-1)
        } 
    }

    return (
        <div>
            <Dialog
                open={showDialog}
                onClose={() => resetDialog()}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>New Requirement</DialogTitle>
                <DialogContent>
                    <Navigation step={activeStep} steps={steps}/>
                    <br /> 
                    {/* Step 1 */}
                    {activeStep === 0 && (
                        <div>
                        <Typography variant='h5'>Select the Requirement type</Typography> 
                        <br />
                        <br /> 
                        <Stack spacing={2}>
                            {options.map(option => (
                                <Paper 
                                    className={classes.type_option}
                                    onClick={() => setType(option.option)}
                                    style={{backgroundColor: type === option.option ? COLOR_THEME.selected_blue : 'white'}}
                                    key={option.header}
                                >
                                    <div style={{padding: '15px'}}>
                                        {option.icon}
                                    </div>
                                    <div>
                                        <Typography variant='h6'>{option.header}</Typography>
                                        <Typography variant='subtitle1'>{option.sub}</Typography>
                                    </div>
                                </Paper>
                            ))}
                        </Stack>
                        </div>
                    )}
                    {/* Step 2 */}
                    {activeStep === 1 && (
                        <div>
                        <Typography variant='h5'>Select the Requirement Type</Typography> 
                        <br />
                        <br />
                        <Typography variant='h6'>Type</Typography> 
                        <Autocomplete
                            disableClearable
                            id="combo-box-demo"
                            options={requirement_types.filter(e => e.type === type)}
                            sx={{ width: '25vw' }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                            value={category}
                            onChange={(e, value) => {
                                setCategory(value)
                                if (title === '') {
                                    setTitle(value.name)
                                }
                            }}                
                        />
                        <br />
                        <Typography variant='h6'>Title</Typography> 
                        <TextField style={{minWidth: '25vw'}} id="standard-basic" variant="outlined" 
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            placeholder={'Title'}
                        />
                        <br />
                        </div>
                    )}
                    {/* Step 3 */}
                    {activeStep === 2 && (
                        <div>
                            <Typography variant='h5'>Select the responsible companies</Typography> 
                            <Typography variant='subtitle2'>Mark each company that is responsible for {options.filter(e => e.option===type)[0].header}. We will create a requirement for each company selected.</Typography> 
                            <Typography variant='subtitle2'>All companies with existing requirement with the same title have been removed from the list below to avoid duplicates.</Typography> 
                            <br />
                            <div>
                                <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                                    <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell padding="checkbox">
                                                    {!multiRequirement && (
                                                        <Checkbox
                                                            color="primary"
                                                            checked={localCompanyList.length > 0 && selectedCompanies.length === localCompanyList.length}
                                                            onChange={handleSelectAllClick}
                                                            inputProps={{"aria-label": "select all"}}
                                                        />
                                                    )}
                                                </TableCell>
                                                <TableCell>Name</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {localCompanyList.map((row, index) => (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleCheckboxClick(event, row.id)}
                                                    tabIndex={-1}
                                                    selected={isSelected(row.id)}
                                                    key={row.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            checked={isSelected(row.id)}
                                                            icon = {multiRequirement ? <RadioButtonUncheckedIcon /> : <CheckBoxOutlineBlankIcon />}
                                                            inputProps={{
                                                                "aria-labelledby": `enhanced-table-checkbox-${index}`
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    )}
                    <br />
                    <Typography variant='subtitle2'>These new requirements will be created in "Draft" mode and clicking "Create" will not send the email to the subs just yet.</Typography>
                    <br />
                    {activeStep === 1 && (
                        <Typography variant='subtitle2'>⭐ New Feature ⭐ You can now create multiple requirements with different titles by adding multiple titles seperated by a comma. Note that for multiple requirements, you'll only be able to select one subcontractor.</Typography>
                    )}
                </DialogContent>
                <DialogActions style={{padding: '15px', paddingRight: '25px', paddingBottom: '25px'}}>
                    <Button onClick={() => ChangeStep('back')}>{activeStep === 0 ? 'Cancel' : 'Back'}</Button>
                    <Button onClick={() => ChangeStep('next')} autoFocus variant="contained" className={classes.nextButton}
                        disabled={((activeStep===0) && type)||((activeStep===1) && category.id)||((activeStep===2) && selectedCompanies.length>0) ? false:true} 
                    >
                        {activeStep !== 2 ? 'Next' : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default NewRequirement;