import React from 'react';
import { styled } from '@material-ui/core/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { COLOR_THEME } from '../common/constants'

import Check from '@mui/icons-material/Check';

const steps = [
    "Select project",
    "Add teammates"
];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)"
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: COLOR_THEME.classic_blue
      }
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: COLOR_THEME.classic_blue
      }
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 2
    }
  }));
  
const QontoStepIconRoot = styled("div")(({ theme, ownerstate }) => ({
    color: theme.palette.mode === "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerstate.active && {
      color: COLOR_THEME.classic_blue
    }),
    "& .QontoStepIcon-completedIcon": {
      color: COLOR_THEME.classic_blue,
      zIndex: 1,
      fontSize: 18
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: ownerstate.active ? COLOR_THEME.classic_blue : "#eaeaf0"
    }
  }));
  
function QontoStepIcon(props) {
    const { active, completed, className } = props;
  
    return (
      <QontoStepIconRoot ownerstate={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
}  

  
export default function Navigation(props) {
    return (
        <Stepper alternativeLabel activeStep={props.step} connector={<QontoConnector />}>
            {steps.map((label) => (
                <Step key={label}>
                    <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}
  