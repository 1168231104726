import React, { Component } from 'react';
import Header from '../../components/pre-security/header';
import MainBanner from '../../components/pre-security/mainBanner';
import LandingPageContent from '../../components/pre-security/landingPageContent';
import Footer from '../../components/pre-security/footer';


class LandingPageContainer extends Component {

    render() {
        return (
            <>
                {/* ***** Header Area ***** */}
                {/* <Header /> */}
                {/* ***** Main Banner Area ***** */}
                <MainBanner />
                <hr style={{'margin': '0rem'}}/>
                {/* ***** Footer ***** */}
                <a id='info'>
                <LandingPageContent />
                </a>
                {/* <Footer /> */}
            </>
        );
    }
}

export default LandingPageContainer;
