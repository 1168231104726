import Axios from 'axios';

import React, { Component } from 'react';
import MainBanner from '../../components/pre-security/support/mainBanner';
import { API_URL } from '../../utils/config/config';
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@mui/lab/Alert';


class SupportPageContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            snackbar_show: false,
            snackbar_message: '',
            snackbar_type: 'info',
        };
        this.submitMessage = this.submitMessage.bind()
    }
    
    submitMessage = async (type, message) => {
        try {
            let body = {
                'type': type,
                'message': message,
                'user_id': localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '',
                'user_email': localStorage.getItem('user_email') ? localStorage.getItem('user_email') : '',
            }

            const response = await Axios.post(`${API_URL}submit_message/`, 
            body,
            {
                headers: {
                    'content-type': 'application/json'
                }
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });

            this.setState({snackbar_show: true});
            this.setState({snackbar_message: 'Message Submitted.'});
            this.setState({snackbar_type: 'success'});   

            setTimeout(() => {this.props.history.push('/');}, 3000);
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }


    render() {
        return (
            <>
                {/* ***** Header Area ***** */}
                {/* <Header /> */}
                {/* ***** Main Banner Area ***** */}
                <Snackbar 
                    anchorOrigin={{ vertical: 'bottom',horizontal: 'center'}}
                    open={this.state.snackbar_show}
                    autoHideDuration={5000}
                    onClose={() => this.setState({snackbar_show: false})}
                >
                    <MuiAlert elevation={6} variant="filled" severity={this.state.snackbar_type}>
                        {this.state.snackbar_message}
                    </MuiAlert>
                </Snackbar>
                <MainBanner 
                    submitMessage={this.submitMessage}
                />
                {/* ***** Footer ***** */}
                {/* <Footer /> */}
            </>
        );
    }
}

export default SupportPageContainer;
