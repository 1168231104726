import React, { Component } from 'react';
import Axios from 'axios';
import _, { filter } from 'lodash';
import { API_URL } from '../../utils/config/config';
import { withAuth0 } from "@auth0/auth0-react";
import Header from '../../components/post-security/common/header';
import Sidebar from '../../components/post-security/common/sidebar';
import Requirements from '../../components/post-security/requirements_view/home';
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@mui/lab/Alert';

import {Typography, Button, Paper, Dialog, withWidth} from '@material-ui/core';
import { Replay } from '@mui/icons-material';


import { withStyles } from '@material-ui/core';
import { NoToneMapping } from 'three';

const Styles = theme => ({
    root_grid: {
        display: 'grid',
        gridTemplateRows: '65px auto',
        overflowY: 'visible',
    },
    header: {
        zIndex: 999999,
        maxHeight: '65px',
    },
    inner_grid: {
        display: 'grid',
        gridTemplateColumns: '155px auto',
        overflowY: 'visible',
    },
});

class ProjectRequirementsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: null,
            company_name: '',
            loading: true,
            error: false,
            offline: false,
            projects_data: [],
            requirements: [],
            progress_data: [],
            company_list: [],
            requirement_types: [],
            procore_users: [],
            activity_log: null,
            notes: null,
            snackbar_show: false,
            snackbar_message: '',
            snackbar_type: 'info',
        };
        this.fetchData = this.fetchData.bind()
        this.createRequirements = this.createRequirements.bind()
        this.requestDocuments = this.requestDocuments.bind()
        this.updateNotes = this.updateNotes.bind()
        this.remindCompanies = this.remindCompanies.bind()
        this.deleteRequirements = this.deleteRequirements.bind()
        this.reviewSubmission = this.reviewSubmission.bind()
        this.saveSubmittersData = this.saveSubmittersData.bind()
    }

    fetchData = async () => {
        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
        try {    
            // Pulling in current requirements
            const response1 = await Axios.get(`${API_URL}fetch_projects/${localStorage.getItem('user_id')}/`, {
                headers: api_header,
            });
            if (response1.status === 400) {
                // Authentication Failed
                this.props.history.push('/');
            }

            // Fetching basics for creating new requirements
            const response3 = await Axios.get(`${API_URL}fetch_new_requirement/${localStorage.getItem('user_id')}/`,{
                headers: api_header,
            }).catch(error => console.log(error));
            if (response3.status === 400) {
                // Authentication Failed
                this.props.history.push('/');
            }

            // Incase this is page for a specfic sub...
            let { company_id } = this.props.match.params;
            company_id = parseInt(company_id) || null

            let company_name = ''
            let company_directory = [];
            let procore_users = [];
            let company_unique_key = '';

            let response2 = null
            if (company_id) {
                let temp_company = response3.data.subs.find(e => e.id === company_id)
                company_name = temp_company.name
                company_unique_key = temp_company.unique_key

                const response4 = await Axios.get(`${API_URL}fetch_project_personnel_directory/${localStorage.getItem('user_id')}/${company_id}/`,{
                    headers: api_header,
                }).catch(error => {
                    if (error.response.status === 400) {
                        this.props.history.push('/');
                    }
                    console.log(error)
                });

                company_directory = response4.data.active_users
                procore_users = response4.data.procore_users

                response2 = await Axios.get(`${API_URL}fetch_all_requirements/${localStorage.getItem('user_id')}/${company_id}/`, {
                    headers: api_header,
                }).catch(error => {
                    if (error.response.status === 400) {
                        this.props.history.push('/');
                    }
                    console.log(error)                    
                });
            } else {
                response2 = await Axios.get(`${API_URL}fetch_all_requirements/${localStorage.getItem('user_id')}/0/`, {
                    headers: api_header,
                }).catch(error => {
                    if (error.response.status === 400) {
                        this.props.history.push('/');
                    }
                    console.log(error)                    
                });
            }

            this.setState({
                loading: false,
                projects_data: response1.data.data,
                requirements: response2.data.data_list,
                requirements_by_company: response2.data.data_by_company,
                requirements_by_requirement_type: response2.data.data_by_requirement_type,
                progress_data: response2.data.progress_data,
                activity_log: response2.data.activity_log,
                notes: response2.data.notes,
                company_list: company_id ? response3.data.subs.filter(e => e.id === company_id): response3.data.subs,
                requirement_types: response3.data.types,
                company_id: company_id,
                company_name: company_name,
                company_directory: company_directory,
                company_unique_key: company_unique_key,
                procore_users: procore_users
            })
        } catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    createRequirements = async (data) => {
        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }

        try {
            const response = await Axios.post(`${API_URL}create_requirements/${localStorage.getItem('user_id')}/`,
            data,
            {
                headers: api_header,
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });

            if (response.data.valid) {
                console.log('SUCCESS')
                this.fetchData()
            }
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    requestDocuments = async (data) => {
        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }

        try {
            const response = await Axios.post(`${API_URL}request_documents/${localStorage.getItem('user_id')}/`,
            data,
            {
                headers: api_header,
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });

            if (response.data.valid) {
                this.fetchData()
            }
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    updateNotes = async (company_id,data) => {
        this.setState({snackbar_show: true});
        this.setState({snackbar_message: 'Saving...'});
        this.setState({snackbar_type: 'info'});  

        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }

        try {
            const response = await Axios.post(`${API_URL}update_notes/${localStorage.getItem('user_id')}/${company_id}/`,
            data,
            {
                headers: api_header,
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });

            if (response.data.valid) {
                this.setState({
                    ...this.state,
                    snackbar_show: true,
                    snackbar_message: 'Notes successfully saved.',
                    snackbar_type: 'success'            
                });
                this.fetchData()
            }
        }
        catch (error) {
            console.log(error);
            this.setState({ 
                ...this.state, 
                error: error, 
                loading: false,
                snackbar_show: true,
                snackbar_message: 'Failed to save the notes.',
                snackbar_type: 'error'            
            });
        }
    
    }

    remindCompanies = async (data) => {
        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }

        try {
            const response = await Axios.post(`${API_URL}remind_companies/${localStorage.getItem('user_id')}/`,
            data,
            {
                headers: api_header,
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });

            if (response.data.valid) {
                this.fetchData()
            }
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    deleteRequirements = async (data) => {
        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }

        try {
            const response = await Axios.post(`${API_URL}delete_requirements/${localStorage.getItem('user_id')}/`,
            data,
            {
                headers: api_header,
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });
            if (response.data.valid) {
                console.log('SUCCESS')
                this.fetchData()
            }
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    reviewSubmission = async (data,requirement_id,mode) => {
        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }

        try {
            const response = await Axios.post(`${API_URL}review_submission/${localStorage.getItem('user_id')}/${requirement_id}/${mode}/`,
            data,
            {
                headers: api_header,
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });

            if (response.data.valid) {
                this.fetchData()
            }
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }


    saveSubmittersData = async (data) => {
        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
        let { company_id } = this.props.match.params;
        try {
            const response = await Axios.post(`${API_URL}save_project_personnel_company_directory/${localStorage.getItem('user_id')}/${company_id}/`,
            data,
            {
                headers: api_header,
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });

            if (response.data.valid) {
                console.log('SUCCESS')
                this.fetchData()
            }
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }


    componentDidMount() {
        this.fetchData();
    }

    render() {
        const { classes } = this.props;        

        return (
            <>
                <div className={classes.root_grid}>
                    <div className={classes.header}>
                        <Header />
                    </div>
                    <div>
                        {this.state.offline ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                You are offline!
                            </div>
                        ) : this.state.error ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                                    <strong>An error occured!</strong>
                                </Typography>
                                <center>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => window.location.reload()}
                                    >
                                        <Replay /> Try again
                                    </Button>
                                </center>
                            </div>
                        ) : (      
                                <div className={classes.inner_grid}>
                                    <Snackbar 
                                        anchorOrigin={{ vertical: 'bottom',horizontal: 'center'}}
                                        open={this.state.snackbar_show}
                                        autoHideDuration={3000}
                                        onClose={() => this.setState({snackbar_show: false})}
                                    >
                                        <MuiAlert elevation={6} variant="filled" severity={this.state.snackbar_type}>
                                            {this.state.snackbar_message}
                                        </MuiAlert>
                                    </Snackbar>

                                    <div><Sidebar /></div>
                                    <div  className={classes.main}>
                                        <Requirements 
                                            loading={this.state.loading}
                                            company_id={this.state.company_id}
                                            company_name={this.state.company_name}
                                            company_directory={this.state.company_directory}
                                            company_unique_key={this.state.company_unique_key}
                                            projects_data={this.state.projects_data}
                                            requirements={this.state.requirements}
                                            requirements_by_company={this.state.requirements_by_company}
                                            requirements_by_requirement_type={this.state.requirements_by_requirement_type}
                                            progress_data={this.state.progress_data}
                                            activity_log={this.state.activity_log}
                                            notes={this.state.notes}
                                            company_list={this.state.company_list}
                                            requirement_types={this.state.requirement_types}
                                            createRequirements={this.createRequirements}
                                            procore_users={this.state.procore_users}
                                            saveSubmittersData={this.saveSubmittersData}
                                            requestDocuments={this.requestDocuments}
                                            remindCompanies={this.remindCompanies}
                                            deleteRequirements={this.deleteRequirements}
                                            reviewSubmission={this.reviewSubmission}
                                            update_notes={this.updateNotes}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    <div>
                    </div>                
                </div>

            </>
        );
    }
}

export default withAuth0(withStyles(Styles)(ProjectRequirementsContainer));
