import React, { Component } from 'react';
import Axios from 'axios';
import _, { filter, first } from 'lodash';

import { API_URL } from '../../utils/config/config';
import { withAuth0 } from "@auth0/auth0-react";
import Header from '../../components/post-security/common/header';
import Sidebar from '../../components/post-security/common/sidebar';
import ProjectDirectory from '../../components/post-security/project_directory/project_directory';
import {Snackbar} from '@material-ui/core';
import MuiAlert from '@mui/lab/Alert';

import {Typography, Button, Paper, Dialog, withWidth} from '@material-ui/core';
import { Replay } from '@mui/icons-material';

import { withStyles } from '@material-ui/core';

const Styles = theme => ({
    root_grid: {
        display: 'grid',
        gridTemplateRows: '65px auto',
        overflowY: 'visible',
    },
    header: {
        zIndex: 999999,
        maxHeight: '65px',
    },
    inner_grid: {
        display: 'grid',
        gridTemplateColumns: '155px auto',
        overflowY: 'visible',
    },
});


class ProjectDirectoryContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            offline: false,
            directory_data: [],
            company_directory: [],
            procore_users: [],
            snackbar_show: false,
            snackbar_message: '',
            snackbar_type: 'info',
        };
        this.fetchSubmitterData = this.fetchSubmitterData.bind()
        this.saveSubmittersData = this.saveSubmittersData.bind()
        this.updateNotes = this.updateNotes.bind()
    }

    fetchData = async () => {
        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
        
        try {
            const response = await Axios.get(`${API_URL}fetch_project_directory/${localStorage.getItem('user_id')}/`,{
                headers: api_header,
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });

            this.setState({
                ...this.state,
                loading: false,
                directory_data: response.data.subs,
            })
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    fetchSubmitterData = async (company_id) => {
        this.setState({loading: true})
        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
        
        try {
            const response = await Axios.get(`${API_URL}fetch_project_personnel_directory/${localStorage.getItem('user_id')}/${company_id}/`,{
                headers: api_header,
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });

            this.setState({
                ...this.state,
                loading: false,
                company_directory: response.data.active_users,
                procore_users: response.data.procore_users
            })
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }


    updateNotes = async (company_id,data) => {
        this.setState({snackbar_show: true});
        this.setState({snackbar_message: 'Saving...'});
        this.setState({snackbar_type: 'info'});  

        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }

        try {
            const response = await Axios.post(`${API_URL}update_notes/${localStorage.getItem('user_id')}/${company_id}/`,
            data,
            {
                headers: api_header,
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });

            if (response.data.valid) {
                this.setState({
                    ...this.state,
                    snackbar_show: true,
                    snackbar_message: 'Notes successfully saved.',
                    snackbar_type: 'success'            
                });
                this.fetchData()
            }
        }
        catch (error) {
            console.log(error);
            this.setState({ 
                ...this.state, 
                error: error, 
                loading: false,
                snackbar_show: true,
                snackbar_message: 'Failed to save the notes.',
                snackbar_type: 'error'            
            });
        }
    
    }


    saveSubmittersData = async (data, company_id) => {
        const api_header = {
            'content-type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
        try {
            const response = await Axios.post(`${API_URL}save_project_personnel_company_directory/${localStorage.getItem('user_id')}/${company_id}/`,
            data,
            {
                headers: api_header,
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });

            if (response.data.valid) {
                console.log('SUCCESS')
                this.fetchData()
            }
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }


    componentDidMount() {
        this.fetchData();
    }

    render() {  

        const { classes } = this.props;        
        return (
            <>
                <div className={classes.root_grid}>
                    <div className={classes.header}>
                        <Header />
                    </div>
                    <div>
                        {this.state.offline ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                You are offline!
                            </div>
                        ) : this.state.error ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                                    <strong>An error occured!</strong>
                                </Typography>
                                <center>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => window.location.reload()}
                                    >
                                        <Replay /> Try again
                                    </Button>
                                </center>
                            </div>
                        ) : (
                                <div className={classes.inner_grid}>
                                    <Snackbar 
                                        anchorOrigin={{ vertical: 'bottom',horizontal: 'center'}}
                                        open={this.state.snackbar_show}
                                        autoHideDuration={3000}
                                        onClose={() => this.setState({snackbar_show: false})}
                                    >
                                        <MuiAlert elevation={6} variant="filled" severity={this.state.snackbar_type}>
                                            {this.state.snackbar_message}
                                        </MuiAlert>
                                    </Snackbar>
                                    <div><Sidebar /></div>
                                    <div  className={classes.main}>
                                        <ProjectDirectory 
                                            loading={this.state.loading}
                                            directory_data={this.state.directory_data}
                                            company_directory={this.state.company_directory}
                                            procore_users={this.state.procore_users}
                                            fetchSubmitterData={this.fetchSubmitterData}
                                            saveSubmittersData={this.saveSubmittersData}
                                            updateNotes={this.updateNotes}
                                        />
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </>
        );
    }
}

export default withAuth0(withStyles(Styles)(ProjectDirectoryContainer));