import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import { COLOR_THEME } from '../../post-security/common/constants'
import FileUploadDialogBox from './file_upload_dialog_box';

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	topblock: {
		display: 'flex'
	}
}));
 
//Function that pulls the data and sends it to the Table Renderer
const DocumentRequest = props => {
    let {loading, data, url_requirement_id, formSubmitContainer } = props;
    const classes = useStyles();

    const [showDialog, setShowDialog] = useState(false);
    const [requirement_id, setRequirement_id] = useState(url_requirement_id);

    useEffect(() => {
        setRequirement_id(url_requirement_id)
        if (url_requirement_id) {
            setShowDialog(true)
        }
    },[url_requirement_id])

    return (
        <>
          {loading ? (
            <>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                Loading...
              </div>
            </>
            ) : (
            <div className={classes.content}>
				<div className={classes.topblock}>
					<div style={{display: 'flex'}}>
						<Typography variant="h4">Closeout Documentation</Typography>
                        <Typography variant="h4" style={{color: 'gray'}}>&nbsp;for {data.project_name}</Typography>
					</div>
					<div style={{marginLeft: 'auto'}}>
						<Button 
                            variant="outlined" 
                            style={{backgroundColor: COLOR_THEME.classic_blue, color: 'white'}}
                            onClick={()=> window.open('mailto:support@wrapup.build?subject=Need Help Submitting Documents')}
                        >
                            I need help
                        </Button>
					</div>
				</div>
				<br />
				<br />
                {/* File Uplodad Dialog Box */}
                <div>
                    <FileUploadDialogBox 
                        showDialog={showDialog}
                        setShowDialog={setShowDialog}
                        requirement_id={requirement_id}
                        formSubmitContainer={formSubmitContainer}
                        setRequirement_id={setRequirement_id}
                    />
                </div>
				<div>
                    {/* Request Details Box */}
                    <div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Request Details</b></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">Project</TableCell>
                                        <TableCell align="right">{data.project_name}</TableCell>
                                    </TableRow>
                                    <TableRow key={3} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">Requested by</TableCell>
                                        <TableCell align="right">{data.requested_by}</TableCell>
                                    </TableRow>
                                    <TableRow key={2} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">Responsible Contractor</TableCell>
                                        <TableCell align="right">{data.company_name}</TableCell>
                                    </TableRow>
                                    <TableRow key={4} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">Asignees</TableCell>
                                        <TableCell align="right">{data.assignees.map(e => e.name).join(', ')}</TableCell>
                                    </TableRow>
                                    {/* <TableRow key={5} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">Attachments</TableCell>
                                        <TableCell align="right">TobyBaby1, TobyBaby2, TobyBaby3</TableCell>
                                    </TableRow> */}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <br />
                    <br />
                    <Typography variant="h6">Requirements</Typography>
                    <br />
                    <div>
                        <Stack spacing={2}>
                            {data.requirements.map(requirement => (
                                <Paper style={{padding: '10px'}} key={requirement.id}>
                                    <div style={{display: 'flex'}}>
                                        <div>
                                            <Typography variant="h6">{requirement.requirement_title}</Typography>
                                            <Typography variant="subtitle1">{requirement.requirement_type}</Typography>
                                        </div>
                                        {requirement.requirement_sample_file && (
                                        <div style={{marginLeft: 'auto'}}>
                                        <Chip 
                                            icon={<InsertDriveFileIcon />} 
                                            label={'Sample File'} 
                                            color='primary'
                                            size='small'
                                            onClick={() => window.open(requirement.requirement_sample_file, "_blank")}
                                        />
                                        </div>

                                        )}
                                        <div style={{marginLeft: 'auto'}}>
                                            { requirement.status === 'Approved' ? (
                                                <div style={{display:'flex', alignItems:'center'}}>
                                                    <Typography variant="subtitle1">
                                                        You have already completed the requirement.&ensp;
                                                    </Typography>
                                                    <Button 
                                                        variant="outlined"
                                                        disabled 
                                                    >
                                                        Upload Documents
                                                    </Button>
                                                </div>
                                            ) : (
                                                <div style={{display:'flex', alignItems:'center'}}>
                                                    {requirement.status === 'Submitted' && (
                                                        <Typography variant="subtitle1">
                                                            You have already submitted the document, and is pending review.&ensp;
                                                        </Typography>
                                                    )}
                                                    <Button 
                                                        variant="contained" 
                                                        style={{backgroundColor: COLOR_THEME.classic_blue, color: 'white'}}
                                                        onClick={() => {
                                                            setShowDialog(true)
                                                            setRequirement_id(requirement.id)
                                                        }}
                                                    >
                                                        {requirement.status === 'Requested' ? 'Upload Documents' : 'Revise Submission'}
                                                    </Button>  
                                                </div>                                        
                                            )}

                                        </div>

                                    </div>
                                </Paper>
                            ))}
                        </Stack>
                    </div>
				</div>
            </div>
          )}
        </>
    );
};


export default DocumentRequest;