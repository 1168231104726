import React, { Component } from 'react';
import Axios from 'axios';
import _, { filter } from 'lodash';
import {withRouter} from 'react-router-dom';
import { API_URL } from '../../utils/config/config';
import { withAuth0 } from "@auth0/auth0-react";

import Header from '../../components/post-security/common/header';
import Sidebar from '../../components/post-security/common/sidebar';
import NewProject from '../../components/post-security/new_project/new_project';

import {Typography, Button, Paper, Dialog, withWidth} from '@material-ui/core';
import { Replay } from '@mui/icons-material';

import { withStyles } from '@material-ui/core';

const Styles = theme => ({
    root_grid: {
        display: 'grid',
        gridTemplateRows: '65px auto',
        overflowY: 'visible',
    },
    header: {
        zIndex: 999999,
        maxHeight: '65px',
    },
    inner_grid: {
        display: 'grid',
        gridTemplateColumns: '155px auto',
        overflowY: 'visible',
    },
});

class NewProjectContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            offline: false,
            active_projects_list: [],
        };
        this.pushCreateProject = this.pushCreateProject.bind(this);
    }

    fetchActiveProjectsList = async () => {
        try {
            // const { getAccessTokenSilently } = this.props.auth0;
            // const token = await getAccessTokenSilently();

            const response = await Axios.get(`${API_URL}new_project_list/${localStorage.getItem('user_id')}/`, {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            }).catch(error => {
                console.log(error)
                this.props.history.push('/');
            });

            this.setState({
                ...this.state,
                loading: false,
                active_projects_list: response.data.data,
            })
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    pushCreateProject = async (body) => {
        try {
            const response = await Axios.post(`${API_URL}create_new_project/${localStorage.getItem('user_id')}/`,
            body,
            {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            }).catch(error => {
                this.props.history.push('/');
                this.setState({ ...this.state, error: error, loading: false });
            });

            if (response.data.valid) {
                localStorage.setItem('active_project',response.data.project_id)
                localStorage.setItem('active_project_name',response.data.name)
                localStorage.setItem('active_project_number',response.data.project_number)

                if (response.data.error === 'Project Already Exists') {
                    this.props.history.push('/project_requirements')
                } else {
                    this.props.history.push('/new_project_setup')
                }
            }
        }
        catch (error) {
            console.log(error);
            this.setState({ ...this.state, error: error, loading: false });
        }
    }

    componentDidMount() {
        this.fetchActiveProjectsList();
    }

    render() {  
        const { classes } = this.props;        
        return (
            <>
                <div className={classes.root_grid}>
                    <div className={classes.header}>
                        <Header />
                    </div>
                    <div>
                        {this.state.offline ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                You are offline!
                            </div>
                        ) : this.state.error ? (
                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <Typography variant="h5" style={{ fontFamily: 'inherit' }} gutterBottom>
                                    <strong>An error occured!</strong>
                                </Typography>
                                <center>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => window.location.reload()}
                                    >
                                        <Replay /> Try again
                                    </Button>
                                </center>
                            </div>
                        ) : (      
                                <div className={classes.inner_grid}>
                                    <div><Sidebar /></div>
                                    <div  className={classes.main}>
                                        <NewProject 
                                            loading={this.state.loading}
                                            active_projects_list={this.state.active_projects_list}
                                            pushCreateProject={this.pushCreateProject}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    <div>
                    </div>                
                </div>

            </>
        );
    }
}

export default withAuth0(withStyles(Styles)(NewProjectContainer));
