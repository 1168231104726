import React, {useState, useEffect, useMemo} from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper, TextField } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { COLOR_THEME } from '../common/constants'
// https://github.com/wojtekmaj/react-pdf
import { Document, Page, pdfjs } from "react-pdf";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import fetchFile from '../../../utils/fetchFile';
import { FRONT_END_URL } from '../../../utils/config/config';

// Loading Worker Script for pdf preview (updated CDN as of 7/16/2024)
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	type_option: {
		display: 'flex',
        '&:hover': {
            border: '1px solid #093D61',
        }
	},
    approveButton: {
        color: 'white', 
        backgroundColor: '#198754',        
        "& .Mui-disabled": {
            backgroundColor: 'gray',
            }
    },
    rejectButton: {
        color: '#dc3545', 
        border: '#dc3545 1px solid',        
        "& .Mui-disabled": {
            backgroundColor: 'gray',
            }
    },
    downloadButton: {
        color: 'white', 
        backgroundColor: COLOR_THEME.classic_blue,        
        "& .Mui-disabled": {
            backgroundColor: 'gray',
            }
    },
}));
 
const ReviewSubmissionDialog = props => {
    let { showReviewSubmissionDialog, setShowReviewSubmissionDialog, reviewSubmissionDetails, reviewSubmission} = props;
    const classes = useStyles();
    // Navigator
    const [activeStep, setActiveStep] = useState(0);
    const ChangeStep = (action) => {
        if (action==='approve') {
            reviewSubmission(
                {
                    'title': title
                },
                reviewSubmissionDetails.id,
                'approve'
            )
            // Resetting state variables
            resetDialog()
        } else if (action==='reject') {
            if (activeStep === 0) {
                setActiveStep(activeStep+1)
            } else {
                reviewSubmission(
                    {
                        'message': message,
                        'title': title
                    },
                    reviewSubmissionDetails.id,
                    'reject'
                )    
                resetDialog()
            }
        }
    }

    const resetDialog = () => {
        // Resetting state variables
        setShowReviewSubmissionDialog(false)
        setActiveStep(0)
        setMessage('Please update and resubmit the document.')
        setScale(1.5)
    }

    // Step 1
    const [numPages, setNumPages] = useState(null);
    const onDocumentLoadSuccess = (e) => {
        setNumPages(e.numPages);
    }   
    const onDocumentLoadError = (error) => {
        console.error('error loading document:', error);
    }   
    const [title, setTitle] = useState(reviewSubmissionDetails.title);  
    useEffect(() => {
        setTitle(reviewSubmissionDetails.title)
    },[reviewSubmissionDetails])

    const filePath = useMemo(() => reviewSubmissionDetails.file_path, [reviewSubmissionDetails.file_path]);

    const [scale,setScale] = useState(1.5);
    const handleZoom = (event, newAlignment) => {
        setScale(scale+parseInt(newAlignment)/4);
    };
    // Step 2:
    const [message, setMessage] = useState('Please update and resubmit the document.')
    const changeText = (e) => setMessage(e.target.value)
    return (
        <div>
            <Dialog
                open={showReviewSubmissionDialog}
                onClose={() => resetDialog()}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Review Submission</DialogTitle>
                <DialogContent>
                    <>
                        {((activeStep === 0) && reviewSubmissionDetails.file_path) && (
                            <>
                                {reviewSubmissionDetails.multiple_files && (
                                    <Typography variant='h6'style={{color: 'red'}}>Please note that multiple files were uploaded. They have been stapled together into a single file.</Typography>
                                )}
                                <br />

                                {reviewSubmissionDetails.status === 'Submitted' && (
                                    <div style={{display:'inline-flex', alignItems:'center'}}>
                                        <Typography variant='h6'>Title:</Typography>&ensp;
                                        <TextField style={{minWidth: '25vw'}} id="standard-basic" variant="outlined" 
                                            value={title}
                                            onChange={e => setTitle(e.target.value)}
                                            placeholder={'Title'}
                                        />
                                    </div>
                                )}
                                {(reviewSubmissionDetails.file_type === 'pdf') ? (
                                    <>
                                        <div style={{display: 'flex'}}>
                                            <ToggleButtonGroup style={{marginLeft: 'auto'}}
                                                onChange={handleZoom}
                                            >
                                                <ToggleButton value='1' style={{color: 'black'}}>
                                                    <ZoomInIcon/>
                                                </ToggleButton>
                                                <ToggleButton value='-1' style={{color: 'black'}}>
                                                    <ZoomOutIcon/>
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>
                                        <br />
                                        <div style={{border: '1px solid black', maxHeight:'70vh', overflowY:'auto'}}>
                                            <Document 
                                                file={reviewSubmissionDetails.file_path}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                            >
                                                {Array.from({length: numPages}, (_, i) => i + 1).map(page => (
                                                    <>
                                                        <Page 
                                                            pageNumber={page}
                                                            renderAnnotationLayer={false}
                                                            renderTextLayer={false}
                                                            scale={scale}
                                                        />
                                                        <hr />
                                                    </>
                                                ))}
                                            </Document>
                                        </div>
                                    </>
                                ) : (
                                    <Typography>
                                        This is not a pdf file. Please download it to view it.
                                    </Typography>
                                )}
                            </>
                        )}
                        {(activeStep === 1) && (
                            <div>
                                <Typography variant='h6'>To</Typography>
                                <TextField id="outlined-basic" variant="outlined" disabled size="small" style={{minWidth:'100%'}} multiline
                                    defaultValue={reviewSubmissionDetails.company_name}
                                    // defaultValue={[...new Set(requirement_list.filter(e => selectedRequirements.includes(e.company_id)).map(e => e.company_name))].join(', ')}
                                />
                                <br /><br />
                                <Typography variant='h6'>Body</Typography>
                                <TextField id="outlined-basic" variant="outlined" size="small" style={{minWidth:'100%'}} multiline
                                    value={message} onChange={e => changeText(e)}
                                />
                            </div>
                        )}
                    </>
                </DialogContent>
                <DialogActions style={{padding: '15px', paddingRight: '25px', paddingBottom: '25px'}}>
                    {activeStep === 0 && (
                        <div style={{marginRight: 'auto'}}>
                            &ensp;
                            <Button
                                component={Link}
                                to={{pathname: filePath}}
                                target="_blank"
                                download
                                variant="contained" className={classes.downloadButton}>
                                Download
                            </Button>
                            {console.log(reviewSubmissionDetails.file_path)}
                            &ensp;
                            <Button 
                                onClick={() => window.open(FRONT_END_URL+'/portal/document_requests/'+reviewSubmissionDetails.company_unique_key+'/'+reviewSubmissionDetails.id, '_blank')} 
                                variant="contained" className={classes.downloadButton}>
                                Resubmit
                            </Button>
                        </div>
                    )}
                    {((reviewSubmissionDetails.status === 'Submitted')||(reviewSubmissionDetails.status === 'Approved')) && (
                        <>
                            <Button onClick={() => ChangeStep('reject')} variant="outlined" className={classes.rejectButton}>Reject</Button>
                            {activeStep === 0 && (
                                <Button onClick={() => ChangeStep('approve')} variant="contained" className={classes.approveButton}>
                                    Approve
                                </Button>
                            )}
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};


export default ReviewSubmissionDialog;