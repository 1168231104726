import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { COLOR_THEME } from '../common/constants'
import Checkbox from "@mui/material/Checkbox";

import Navigation from './navigation';

const steps = [
    "Select Requirements",
    "Review"
];

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	type_option: {
		display: 'flex',
        '&:hover': {
            border: '1px solid #093D61',
        }
	},
    nextButton: {
        color: 'white', 
        backgroundColor: COLOR_THEME.classic_blue,        
        "& .Mui-disabled": {
            backgroundColor: 'gray',
            }
    },
}));
 
const DeleteRequirementsDialog = props => {
    let { showDeleteRequirementsDialog, setShowDeleteRequirementsDialog, requirement_list,deleteRequirements } = props;
    const classes = useStyles();
    // rows
    const [deleteRows, setDeleteRows] = useState([])
    const [selectedDeleteRequirements, setSelectedDeleteRequirements] = useState([]);

    useEffect(() => {
        // Essentially part of dialog reset. Making sure when you reopen dialog box, it's fresh.
        if (requirement_list && requirement_list.length > 0) {
            setDeleteRows(requirement_list.filter(e => (e.status === 'Draft')||e.status === 'Requested'))
        }
        setSelectedDeleteRequirements([])
    },[requirement_list])

    // Navigator
    const [activeStep, setActiveStep] = useState(0);
    const ChangeStep = (action) => {
        if (action==='next') {
            setActiveStep(activeStep+1)
            if (activeStep === 1) {
                // Request the documents from Subs               
                deleteRequirements(
                    {
                        'requirements': selectedDeleteRequirements, 
                    }
                )
                // Resetting state variables
                resetDialog()
            }
        } else if (action==='back') {
            activeStep === 0 ? resetDialog() : setActiveStep(activeStep-1)
        } 
    }

    const resetDialog = () => {
        // Resetting state variables
        setShowDeleteRequirementsDialog(false)
        setActiveStep(0)
    }

    // Step 1
    const isSelected = (id) => selectedDeleteRequirements.indexOf(id) !== -1;
    
    const handleCheckboxClick = (event, id) => {
        const selectedIndex = selectedDeleteRequirements.indexOf(id);
        let newSelected = [];
   
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedDeleteRequirements, id);
            } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedDeleteRequirements.slice(1));
            } else if (selectedIndex === selectedDeleteRequirements.length - 1) {
            newSelected = newSelected.concat(selectedDeleteRequirements.slice(0, -1));
            } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedDeleteRequirements.slice(0, selectedIndex),
                selectedDeleteRequirements.slice(selectedIndex + 1)
            );
        }      
        setSelectedDeleteRequirements(newSelected);
    };

    return (
        <div>
            <Dialog
                open={showDeleteRequirementsDialog}
                onClose={() => resetDialog()}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Delete Requirements</DialogTitle>
                <DialogContent>
                    <>
                        <Navigation step={activeStep} steps={steps}/>
                        <br /> <br/>
                        {(activeStep === 0) && (
                            <div>
                                <Typography variant='h5'>{selectedDeleteRequirements.length} of {deleteRows.length} Requirements Selected</Typography> 
                                <Typography variant='subtitle1'>Select the requirements to be deleted</Typography> 
                                <br />
                                <div>
                                    <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                                        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="checkbox">
                                                    </TableCell>
                                                    <TableCell>Subcontractor</TableCell>
                                                    <TableCell>Title</TableCell>
                                                    <TableCell>Status</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {deleteRows.map((row, index) => (
                                                        <TableRow
                                                            hover
                                                            onClick={(event) => handleCheckboxClick(event, row.id)}
                                                            tabIndex={-1}
                                                            selected={isSelected(row.id)}
                                                            key={row.id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={isSelected(row.id)}
                                                                    inputProps={{
                                                                        "aria-labelledby": `enhanced-table-checkbox-${index}`
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">{row.company_name}</TableCell>
                                                            <TableCell component="th" scope="row">{row.title}</TableCell>
                                                            <TableCell component="th" scope="row">{row.status}</TableCell>
                                                        </TableRow>
                                                    )
                                            )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        )}
                        {(activeStep === 1) && (
                            <div>
                            <Typography variant='h5'>You are about to delete {selectedDeleteRequirements.length} Requirement(s)</Typography> 
                            <Typography variant='subtitle1'>Please review the requirement(s) to be deleted</Typography> 
                            <br />
                            <div>
                                <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
                                    <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell padding="checkbox">
                                                </TableCell>
                                                <TableCell>Subcontractor</TableCell>
                                                <TableCell>Title</TableCell>
                                                <TableCell>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {deleteRows.filter(e => selectedDeleteRequirements.includes(e.id)).map((row, index) => (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        selected={isSelected(row.id)}
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell padding="checkbox">
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">{row.company_name}</TableCell>
                                                        <TableCell component="th" scope="row">{row.title}</TableCell>
                                                        <TableCell component="th" scope="row">{row.status}</TableCell>
                                                    </TableRow>
                                                )
                                        )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    )}
                    </>
                </DialogContent>
                <DialogActions style={{padding: '15px', paddingRight: '25px', paddingBottom: '25px'}}>
                    <Button onClick={() => ChangeStep('back')}>{activeStep === 0 ? 'Cancel' : 'Back'}</Button>
                    <Button onClick={() => ChangeStep('next')} autoFocus variant="contained" className={classes.nextButton}
                        disabled={(selectedDeleteRequirements.length>0) ? false:true} 
                    >
                        {activeStep !== 1 ? 'Next' : 'Delete Requirements'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};


export default DeleteRequirementsDialog;