import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper } from '@material-ui/core';
import { FRONT_END_URL } from '../../../utils/config/config';
import Stack from '@mui/material/Stack';
// Project Directory 
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
// Project Requirements
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
// Project Syncs
import SyncIcon from '@mui/icons-material/Sync';
// Project Delivery
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

const options = [
    {
        header: 'Directory',
        option: 'project_directory',
        icon: <ContactPhoneIcon />,
    },
    {
        header: 'Requirements',
        option: 'project_requirements',
        icon: <LibraryAddCheckIcon />,
    },
    {
        header: 'Procore Sync',
        option: 'procore_syncs',
        icon: <SyncIcon />,
    },
    {
        header: 'Wrap Up Package',
        option: 'project_wrapup',
        icon: <RocketLaunchIcon />,
    }
]


// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	topblock: {
		// display: 'flex'
	},
    type_option: {
        padding: '10px',
        maxWidth: '50vw',
        display: 'flex',
        alignItems: 'center',
        textDecoration:'none',
        '&:hover': {
            border: '1px solid #093D61',
        },

    }
}));
 
//Function that pulls the data and sends it to the Table Renderer
const ProjectHome = props => {
    let {loading } = props;
    const classes = useStyles();
       
    return (
        <>
          {loading ? (
            <>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                Loading...
              </div>
            </>
            ) : (
            <div className={classes.content}>
				<div className={classes.topblock}>
					<div>
						<Typography variant="h4">Project Home</Typography>
						{/* <Typography variant="h6">Turn on the syncs to include the documents from Procore in the zip export</Typography> */}
					</div>
                    <br />
				</div>
				<br />
				<br />
				<div>
                    <Stack spacing={2}>
                        {options.map(option => (
                            <Paper 
                                key={option.header}
                                className={classes.type_option}
                                onClick={() => window.open(FRONT_END_URL+'/'+option.option,'_self')}
                                elevation={4}
                            >
                                <div style={{padding: '15px'}}>
                                    {option.icon}
                                </div>
                                <div>
                                    <Typography variant='h6'>{option.header}</Typography>
                                </div>
                                <div style={{marginLeft:'auto'}}>
                                </div>
                            </Paper>
                        ))}
                    </Stack>
				</div>
            </div>
          )}
        </>
    );
};


export default ProjectHome;