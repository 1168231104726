import React from "react";
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
    let isAuthenticated= false;

    if (localStorage.getItem('access_token')) {
        if ((localStorage.getItem('user_id'))&&(localStorage.getItem('user_id').length > 0)) {
            isAuthenticated = true;
        }
    } else if ((rest.location.pathname === '/projects/')&&(rest.location.search.length > 0)) {
        isAuthenticated = true;
    }

    return (isAuthenticated) ? (
        <Route
            {...rest}
            render={props => 
                <Component {...props}/>
            }
        />
    ) : <Redirect to="/" />;
}

export default (PrivateRoute);
