import React, { Fragment } from 'react';

// Import routing components
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// Import custom components
import NotFound from '../components/error/NotFound';
import { FRONT_END_URL } from '../utils/config/config';

import PublicRoute from './PublicRoute'; // Pre auth routes
import PrivateRoute from './PrivateRoute'; // If not auth, re-route to '/'

// Pre-Security
import LandingPageCont from '../containers/pre-security/LandingPageContainer';
import SupportPageContainer from '../containers/pre-security/SupportPageContainer';
import ContactPageContainer from '../containers/pre-security/ContactPageContainer';
import TermsOfUseContainer from '../containers/pre-security/TermsOfUseContainer';
import DocumentRequestContainer from '../containers/pre-security/DocumentRequestContainer';

// Post-Security
// Wrap Up
import ProjectsContainer from '../containers/post-security/ProjectsContainer';
import SubmissionNotificationContainer from '../containers/post-security/SubmissionNotificationContainer';
import ProjectHomeContainer from '../containers/post-security/ProjectHomeContainer';
import NewProjectContainer from '../containers/post-security/NewProjectContainer';
import NewProjectSetupContainer from '../containers/post-security/NewProjectSetupContainer';
import ProjectDirectoryContainer from '../containers/post-security/ProjectDirectoryContainer';
import ProjectDirectorySetupContainer from '../containers/post-security/ProjectDirectorySetupContainer';
import ProjectRequirementsContainer from '../containers/post-security/ProjectRequirementsContainer';
import ProjectWrapupContainer from '../containers/post-security/ProjectWrapupContainer';
import ProcoreSyncsContainer from '../containers/post-security/ProcoreSyncsContainer';
import ProjectSettingsContainer from '../containers/post-security/ProjectSettingsContainer';


const Router = () => (
    <Fragment>
        <BrowserRouter>
            <Switch>
                <PublicRoute exact path="/" component={LandingPageCont} />
                <PublicRoute exact path="/support" component={SupportPageContainer} />
                <PublicRoute exact path="/contactus" component={ContactPageContainer} />
                <PublicRoute exact path="/terms" component={TermsOfUseContainer} />

                <PublicRoute exact path="/portal/document_requests/:unique_key?/:requirement_id?" component={DocumentRequestContainer} />
                                
                <PrivateRoute exact path="/projects/" component={ProjectsContainer} />
                <PrivateRoute exact path="/submission_notification/:project?/:company?" component={SubmissionNotificationContainer} />
                <PrivateRoute exact path="/project_home/" component={ProjectHomeContainer} />
                <PrivateRoute exact path="/new" component={NewProjectContainer} />
                <PrivateRoute exact path="/new_project_setup/:step?" component={NewProjectSetupContainer} />
                <PrivateRoute exact path="/project_directory" component={ProjectDirectoryContainer} />
                <PrivateRoute exact path="/project_directory_setup" component={ProjectDirectorySetupContainer} />
                <PrivateRoute exact path="/project_requirements/:company_id?" component={ProjectRequirementsContainer} />
                <PrivateRoute exact path="/project_wrapup" component={ProjectWrapupContainer} />
                <PrivateRoute exact path="/procore_syncs" component={ProcoreSyncsContainer} />
                <PrivateRoute exact path="/project_settings/:new_project?" component={ProjectSettingsContainer} />

                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
    </Fragment>
);

export default Router;