import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { Link } from "react-router-dom";
import { COLOR_THEME } from '../../components/post-security/common/constants'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { REACT_APP_REDIRECT_URI, REACT_APP_PROCORE_DOMAIN, FRONT_END_URL } from '../../utils/config/config';


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        minHeight: '100vh',
        background: 'linear-gradient(90deg, #1CB5E0 0%, #000851 100%), #F18D2B',
        mixBlendMode: 'normal',
        position: 'relative',
        height: '100%',
    },
    
    textWrapper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#fff',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    text1: {
        fontFamily: 'Roboto',
        fontWeight: 200,
        fontStyle: 'normal',
        fontSize: '60px',
        lineHeight: '60px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '42px',
        },
        paddingLeft: '1rem',
    },
    text2: {
        fontFamily: 'Roboto',
        fontWeight: 100,
        fontSize: '35px',
        textAlign: 'center',
        paddingBottom: '3px',
    },
    text3: {
        fontFamily: 'Roboto',
        fontWeight: 100,
        // fontSize: '35px',
        textAlign: 'center',
        paddingBottom: '3px',
    },
    icon: {
        display: 'inline-block',
        verticalAlign: 'middle',
        fontSize: '20px',
    },
}));

const MainBanner = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.textWrapper}>
                <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Typography variant='h3' className={classes.text1}>Wrap Up</Typography>
                    <br />
                    <Typography variant='h4' className={classes.text2}>
                        Create Project Closeout Documentation smartly!
                    </Typography>
                    <br />
                    <br />
                    <Button 
                        href={'https://'+REACT_APP_PROCORE_DOMAIN+'/oauth/authorize?client_id='+process.env.REACT_APP_PROCORE_CLIENT_ID+'&response_type=code&redirect_uri='+REACT_APP_REDIRECT_URI} 
                        variant="contained" style={{color: 'white', backgroundColor: COLOR_THEME.procore_orange}}
                    >
                        Login with Procore
                    </Button>
                    <br />
                    <Typography variant='subtitle2' className={classes.text3}>
                        By signing up, you agree with our <a href='https://wrapup.build/terms' target="_blank" style={{color: 'white'}}>terms</a>.
                    </Typography>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <ExpandMoreIcon />
                    <br />
                    <Typography variant='subtitle1' className={classes.text3}>
                        Scroll down for more information
                    </Typography>
                </>
            </div>
        </div>
    );
};

export default MainBanner;
