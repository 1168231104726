import React, {useState, useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles, Drawer, Toolbar, List, ListItem, Divider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { COLOR_THEME } from '../common/constants'
import {FRONT_END_URL} from '../../../utils/config/config'

// Icons
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// Projects view
import HomeWorkIcon from '@mui/icons-material/HomeWork';
// Custom Settings
import SettingsIcon from '@mui/icons-material/Settings';
// Project Home
import HomeIcon from '@mui/icons-material/Home';
// Project Directory 
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
// Project Requirements
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
// Project Syncs
import SyncIcon from '@mui/icons-material/Sync';
// Project Delivery
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

const useStyles = makeStyles(theme => ({
    root: {
        // display: 'flex',
        zIndex: 9,
        color: '#d8d8d8'
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    active: {
        backgroundColor: COLOR_THEME.selected_blue,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(6, 1, 0, 0),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    divider: {
        margin: '10px',
    }
}));

const NavLinkMui = React.forwardRef((props, ref) => {
    const classes = useStyles();
    return <NavLink {...props} ref={ref} activeClassName={classes.active} />;
});

const Sidebar = (props) => {
    
    const classes = useStyles();
    let disable_buttons = localStorage.getItem('active_project') ? false : true;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                className={classes.drawer}
            >
                <Toolbar className={classes.toolbar}>
                    <List>
                        <br />
                        {/* Projects */}
                        <Tooltip title='Projects'>
                        <ListItem
                            button
                            key={'Projects'}
                            className={classes.sublist}
                            component={NavLinkMui}
                            to="/projects"
                        >
                            <ListItemIcon>
                                <HomeWorkIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Projects'}
                                classes={{ primary: classes.sublist_text }}
                            />
                        </ListItem>
                        </Tooltip>

                        <Divider className={classes.divider}/>


                        {/* Project Home */}
                        <ListItem
                            button
                            key={'Home'}
                            className={classes.sublist}
                            component={NavLinkMui}
                            to="/project_home"
                            disabled ={disable_buttons}
                        >
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Project Home'}
                                classes={{ primary: classes.sublist_text }}
                            />
                        </ListItem>
                        {/* Settings View */}
                        <ListItem
                            button
                            key={'Settings'}
                            className={classes.sublist}
                            component={NavLinkMui}
                            to="/project_settings"
                            disabled ={disable_buttons}
                        >
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Settings'}
                                classes={{ primary: classes.sublist_text }}
                            />
                        </ListItem>
                        {/* Project Directory */}
                        <ListItem
                            button
                            key={'Directory'}
                            className={classes.sublist}
                            component={NavLinkMui}
                            to="/project_directory"
                            disabled ={disable_buttons}
                        >
                            <ListItemIcon>
                                <ContactPhoneIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Directory'}
                                classes={{ primary: classes.sublist_text }}
                            />
                        </ListItem>
                        {/* Project Requirements */}
                        <ListItem
                            button
                            key={'Requirements'}
                            className={classes.sublist}
                            // component={NavLinkMui}
                            // to="/project_requirements"
                            disabled ={disable_buttons}
                            onClick={() => window.open(FRONT_END_URL+'/project_requirements','_self')}
                        >
                            <ListItemIcon>
                                <LibraryAddCheckIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Requirements'}
                                classes={{ primary: classes.sublist_text }}
                            />
                        </ListItem>
                        {/* Project Syncs */}
                        <ListItem
                            button
                            key={'Syncs'}
                            className={classes.sublist}
                            component={NavLinkMui}
                            to="/procore_syncs"
                            disabled ={disable_buttons}
                        >
                            <ListItemIcon>
                                <SyncIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Procore Syncs'}
                                classes={{ primary: classes.sublist_text }}
                            />
                        </ListItem>

                        {/* Wrapup */}
                        <ListItem
                            button
                            key={'Closeout'}
                            className={classes.sublist}
                            component={NavLinkMui}
                            to="/project_wrapup"
                            disabled ={disable_buttons}
                        >
                            <ListItemIcon>
                                <RocketLaunchIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={'Wrap Up Package'}
                                classes={{ primary: classes.sublist_text }}
                            />
                        </ListItem>

                    </List>
                </Toolbar>
            </Drawer>
        </div>
    );
    // }
};

export default Sidebar;
