import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper } from '@material-ui/core';

import { COLOR_THEME } from '../common/constants'

import VerticalStepper from './stepper'


// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	topblock: {
		display: 'flex'
	},
	mainblock: {
		minHeight: '50vh',
	}
}));
 
//Function that pulls the data and sends it to the Table Renderer
const NewProjectSetup = props => {
    let {loading, projects_data, step_from_url } = props;
    const classes = useStyles();
    // Step goes from 0 > 1 > 2

    const [step, setStep] = useState(0);
    useEffect(() => {
        setStep(parseInt(step_from_url))
    },[step_from_url])

    return (
        <>
          {loading ? (
            <>
              <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                Loading...
              </div>
            </>
            ) : (
            <div className={classes.content}>
				<div className={classes.topblock}>
					<div>
						<Typography variant="h4">Let's setup your project...</Typography>
					</div>
				</div>
				<br />
				<div>
                    <VerticalStepper activeStep={step} setActiveStep={setStep}/>
				</div>
            </div>
          )}
        </>
    );
};


export default NewProjectSetup;