import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper, Radio, RadioGroup, FormControlLabel, TextField } from '@material-ui/core';
import { Link } from "react-router-dom";
import { COLOR_THEME } from '../../../components/post-security/common/constants'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        minHeight: '100vh',
        background: 'linear-gradient(90deg, #1CB5E0 0%, #000851 100%), #F18D2B',
        mixBlendMode: 'normal',
        position: 'relative',
        height: '100%',
    },
    
    textWrapper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#fff',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    text1: {
        fontFamily: 'Roboto',
        fontWeight: 200,
        fontStyle: 'normal',
        fontSize: '60px',
        lineHeight: '60px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '42px',
        },
        paddingLeft: '1rem',
    },
    text2: {
        fontFamily: 'Roboto',
        fontWeight: 100,
        fontSize: '35px',
        textAlign: 'center',
        paddingBottom: '3px',
    },
    icon: {
        display: 'inline-block',
        verticalAlign: 'middle',
        fontSize: '20px',
    },
    paper: {
        display: 'block',
        padding: '20px'
    },
    submitButton: {
        color: 'white', 
        backgroundColor: COLOR_THEME.procore_orange, 
        '&:disabled' : {backgroundColor: 'pink'}
    }
}));

const MainBanner = (props) => {
    let {submitMessage } = props;
    const classes = useStyles();
    const [type, setType] = useState('Other')
    const [message, SetMessage] = useState('')



    return (
        <div className={classes.root}>
            <div className={classes.textWrapper}>
                <>
                    <Typography variant='h4' className={classes.text2}>
                    Reach out to the Wrap Up Team
                    </Typography>
                    <br />
                    <Paper className={classes.paper}>
                        <div style={{display: 'flex', alignItems: 'center', flexWrap:'wrap'}}>
                            <Typography variant='subtitle1'>
                                Query Type:
                            </Typography>&ensp;
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue={type}
                                onChange={(e) => setType(e.target.value)}
                            >
                                <FormControlLabel value="Feature Request" control={<Radio />} label="Feature Request" />
                                <FormControlLabel value="Need Support" control={<Radio />} label="Need Support" />
                                <FormControlLabel value="Other" control={<Radio />} label="Other" />
                            </RadioGroup>                        
                        </div>
                        <br />
                        <TextField
                            label="Message" variant="standard" 
                            style={{minWidth:'100%', border: '1px black solid'}}
                            multiline
                            minRows={4}
                            placeholder="Write your message here..."
                            value={message}
                            onChange={(e) => SetMessage(e.target.value)}
                        />

                    </Paper>
                    <br />
                    <Button 
                        variant="contained" className={classes.submitButton}
                        onClick={() => submitMessage(type, message)}
                        disabled={message === '' ? true : false}
                    >
                        Submit
                    </Button>
                </>
            </div>
        </div>
    );
};

export default MainBanner;
