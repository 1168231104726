import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Paper, TextField } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import { COLOR_THEME } from '../common/constants'
import Checkbox from "@mui/material/Checkbox";
import AddIcon from '@mui/icons-material/Add';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Navigation from './navigation';
import Autocomplete from '@mui/material/Autocomplete';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import DescriptionIcon from '@mui/icons-material/Description';

// Styles for overall page
const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: '95vw',
        minHeight: '85vh',
    },
	content: {
		paddingTop: '50px',
		padding: '100px',
	},
	type_option: {
		display: 'flex',
        '&:hover': {
            border: '1px solid #093D61',
        }
	},
    nextButton: {
        color: 'white', 
        backgroundColor: COLOR_THEME.classic_blue,        
        "& .Mui-disabled": {
            backgroundColor: 'gray',
            }
    },
}));
 
//Function that pulls the data and sends it to the Table Renderer
const EditCompanySubmitters = props => {
    let { showEditCompanySubmittersDialog, setShowEditCompanySubmittersDialog, company_directory, procore_users, saveSubmittersData, company_id } = props;
    const classes = useStyles();

    let list_of_curret_user_ids = company_directory ? company_directory.map(e => e.id) : []
    const [currentRows, setCurrentRows] = useState(company_directory)
    const [procoreRows, setProcoreRows] = useState(procore_users.filter(e => !list_of_curret_user_ids.includes(e.id)))

    const editRows = (row_id, type) => {
        if (type === 'add') {
            let temp_row = procoreRows.find(e => e.id === row_id)
            let temp_rows = [...currentRows]
            temp_rows.push(temp_row)
            setCurrentRows(temp_rows)
            setProcoreRows([...procoreRows].filter(e => e.id !== row_id))
        } else if (type === 'remove'){
            let temp_row = currentRows.find(e => e.id === row_id)
            let temp_rows = [...procoreRows]
            temp_rows.push(temp_row)
            setCurrentRows([...currentRows].filter(e => e.id !== row_id))
            setProcoreRows(temp_rows)
        }
    }

    const saveData = () => {
        saveSubmittersData(currentRows.map(e => e.id), company_id)
        setShowEditCompanySubmittersDialog(false)
    }

    const resetDialog = () => {
        // Resetting state variables
        setShowEditCompanySubmittersDialog(false)
        setCurrentRows(company_directory)
        setProcoreRows(procore_users.filter(e => !list_of_curret_user_ids.includes(e.id)))
    }

    return (
        <div>
            <Dialog
                open={showEditCompanySubmittersDialog}
                onClose={() => resetDialog()}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>Edit Submitters</DialogTitle>
                <DialogContent>
                    <Typography variant='subtitle1'>Current submitters:</Typography>
                    {/* Current Users */}
                    <TableContainer component={Paper}>
                        <Table 
                            sx={{ minWidth: 650 }} 
                            stickyHeader aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">Email</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {currentRows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" style={{display:'flex', cursor: 'pointer'}}>
                                        {row.first_name} {row.last_name}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.email}
                                    </TableCell>
                                    <TableCell align="right">
                                        <DeleteOutlineIcon onClick={() => editRows(row.id, 'remove')}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br /><br />
                    <Typography variant='subtitle1'>More users on Procore:</Typography>
                    {/* Procore Users */}
                    <TableContainer component={Paper}>
                        <Table 
                            sx={{ minWidth: 650 }} 
                            stickyHeader aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">Email</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {procoreRows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" style={{display:'flex', cursor: 'pointer'}}>
                                        {row.first_name} {row.last_name}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.email}
                                    </TableCell>
                                    <TableCell align="right">
                                        <AddIcon onClick={() => editRows(row.id, 'add')}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </DialogContent>
                <DialogActions style={{padding: '15px', paddingRight: '25px', paddingBottom: '25px'}}>
                    <Button onClick={() => resetDialog()}>Cancel</Button>
                    <Button onClick={() => saveData()} variant="contained" className={classes.nextButton}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};


export default EditCompanySubmitters;