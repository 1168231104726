import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        display: 'inline',
    },
    text: {
        color: 'rgba(0,0,0,0.9)',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
    },
    active: {
        backgroundColor: '#d8d8d8',
        borderRadius: '3px',
    },
    button: {
        padding: theme.spacing(0, 2),
    },
    sectionDesktop: {
        display: 'none',
        color: '#d8d8d8',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        color: '#d8d8d8',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
}));

const Header = () => {
    const classes = useStyles();

    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const open = Boolean(anchorEl);

    const {
        isAuthenticated,
        loginWithRedirect,
      } = useAuth0();

    return (
        <div className={classes.root}>
            <AppBar color="default" position="fixed">
                <Toolbar>
                    {window.location.href.includes('lessons') && (
                        <>
                            <Typography variant="h6" className={classes.title}>
                                <NavLink to="/" className={classes.text}>
                                    Wrap Up                                
                                </NavLink>
                            </Typography>
                        </>
                    )}
                    {!window.location.href.includes('lessons') && (
                        <>
                            <Typography variant="h6" className={classes.title}>
                                <NavLink to="/" className={classes.text}>
                                    Wrap Up
                                </NavLink>
                            </Typography>
                        </>
                    )}
                    <div className={classes.sectionDesktop}>
                        {!isAuthenticated && (
                            <Button className={classes.button} color="default" onClick={() => loginWithRedirect({ appState: { targetUrl: 'http:localhost:3000/project-view' }})}>
                                Sign In
                            </Button>
                        )}
                        {isAuthenticated && (
                            <NavLink to="/project-view" className={classes.text}>
                                <Button className={classes.button} color="default">
                                    Go To Wrap Up
                                </Button>
                            </NavLink>
                        )}
                    </div>
                    <div className={classes.sectionMobile}>
                        {!isAuthenticated && (
                            <Button className={classes.button} color="default" onClick={() => loginWithRedirect({ appState: { targetUrl: 'http:localhost:3000/project-view' }})}>
                                Sign In
                            </Button>
                        )}
                        {isAuthenticated && (
                            <NavLink to="/project-view" className={classes.text}>
                                <Button className={classes.button} color="default">
                                    Go To Wrap Up
                                </Button>
                            </NavLink>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Header;
