import React, { Component } from 'react';
import TermsOfUse from '../../components/pre-security/termsOfUse';


class SupportPageContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    render() {
        return (
            <>
                {/* ***** Header Area ***** */}
                {/* <Header /> */}
                {/* ***** Main Banner Area ***** */}
                <TermsOfUse />
                {/* ***** Footer ***** */}
                {/* <Footer /> */}
            </>
        );
    }
}

export default SupportPageContainer;
